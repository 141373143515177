import { FormEvent, useEffect, useState } from 'react';

/* components */
import { Input } from '../../Common/Input/Input';
import { Select } from '../../Common/Select/Select';

/* configs */
import api from '../../../configs/api';

/* types */
import { Location } from '../../../types/Location';
import { Address } from '../../../types/Address';

/* props */
interface Props {
  location?: Location;
  handleSubmit: (data: Location) => void;
}

export const LocationForm = (props: Props) => {
  const { location } = props;

  const [addresses, setAddresses] = useState<Address[]>([]);
  const [address, setAddress] = useState<string>();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const data = Object.fromEntries(
      new FormData(form).entries()
    ) as unknown as Location;

    props.handleSubmit(data);
  };

  /* get addresses */
  useEffect(() => {
    api.get(`/addresses`).then((response) => setAddresses(response.data));
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
      method="post"
      className="flex flex-col gap-4 mt-8"
    >
      {/* name */}
      <Input
        name="name"
        type="text"
        label="Name"
        defaultValue={location?.name}
      />
      {/* phone */}
      <Input
        name="phone"
        type="text"
        label="Phone"
        defaultValue={location?.phone}
      />
      {/* email */}
      <Input
        name="email"
        type="email"
        label="Email"
        defaultValue={location?.email}
      />
      {/* address */}
      <Select
        name="address_id"
        label="Address"
        value={address || location?.address_id}
        options={addresses.map((address) => {
          return {
            label: `${address.address_line_1}, ${address.city}, ${address.postcode}`,
            value: address.id
          };
        })}
        onChange={(e) => setAddress(e.target.value)}
      />
      {/* submit */}
      <div className="flex justify-end mt-4">
        <button type="submit" className="btn btn-sm btn-primary">
          Submit form
        </button>
      </div>
    </form>
  );
};
