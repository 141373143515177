import { FormEvent } from 'react';

/* components */
import { Input } from '../../Common/Input/Input';

/* types */
import { License } from '../../../types/License';

/* props */
interface Props {
  license?: License;
  handleSubmit: (data: License) => void;
}

export const LicenseForm = (props: Props) => {
  const { license } = props;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const data = Object.fromEntries(
      new FormData(form).entries()
    ) as unknown as License;

    props.handleSubmit(data);
  };

  return (
    <form
      onSubmit={handleSubmit}
      method="post"
      className="flex flex-col gap-4 mt-8"
    >
      <Input
        name="name"
        type="text"
        label="Name"
        defaultValue={license?.name}
      />
      <Input
        name="description"
        type="text"
        label="Description"
        defaultValue={license?.description}
      />
      <Input
        name="additional_information"
        type="text"
        label="Additional Information"
        defaultValue={license?.additional_information}
      />
      {/* actions */}
      <div className="flex justify-end">
        <button type="submit" className="btn btn-sm btn-primary">
          Submit form
        </button>
      </div>
    </form>
  );
};
