import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated
} from '@azure/msal-react';
import classNames from 'classnames';
import { useEffect } from 'react';

/* hooks */
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

/* components */
import { Link } from './Link/Link';

/* icons */
import {
  faAddressBook,
  faBook,
  faCalendarAlt,
  faChartBar,
  faDriversLicense,
  faGraduationCap,
  faHome,
  faLocation,
  faMotorcycle,
  faUser
} from '@fortawesome/free-solid-svg-icons';

interface Props {
  open: boolean;
  toggle: () => void;
}

export const Menu = (props: Props) => {
  const { open } = props;
  const { width } = useWindowDimensions();
  const isAuthenticated = useIsAuthenticated();

  /* open or close the nav on screen resize */
  useEffect(() => {
    if (!isAuthenticated && open) {
      props.toggle();
    }

    if (isAuthenticated) {
      if (width <= 768 && open) props.toggle();
      if (width > 768 && !open) props.toggle();
    }
  }, [width]); // eslint-disable-line

  return (
    <nav
      className={classNames(
        'menu w-48 bg-neutral-000 z-40 transform -ml-48 fixed',
        'h-full transition duration-300 ease-in-out shadow',
        open ? 'translate-x-48' : 'translate-x-0'
      )}
    >
      <ul
        className="flex flex-col gap-2 p-2"
        onClick={() => width <= 768 && props.toggle()}
      >
        {/* public */}
        <UnauthenticatedTemplate>
          <Link to="home" icon={faHome} badge={0}>
            Home
          </Link>
          <Link to="book" icon={faBook} badge={0}>
            Book
          </Link>
        </UnauthenticatedTemplate>

        {/* private */}
        <AuthenticatedTemplate>
          <Link to="" icon={faChartBar} badge={0}>
            Dashboard
          </Link>
          <Link to="bookings" icon={faBook} badge={0}>
            Bookings
          </Link>
          <Link to="customers" icon={faUser} badge={0}>
            Customers
          </Link>
          <Link to="calendar" icon={faCalendarAlt} badge={0}>
            Calendar
          </Link>
          <Link to="courses" icon={faGraduationCap} badge={0}>
            Courses
          </Link>
          <Link to="licenses" icon={faDriversLicense} badge={0}>
            Licenses
          </Link>
          <Link to="locations" icon={faLocation} badge={0}>
            Locations
          </Link>
          <Link to="addresses" icon={faAddressBook} badge={0}>
            Addresses
          </Link>
          <Link to="vehicles" icon={faMotorcycle} badge={0}>
            Vehicles
          </Link>
        </AuthenticatedTemplate>
      </ul>
    </nav>
  );
};
