import { FormEvent, useEffect, useState } from 'react';

/* components */
import { Input } from '../../Common/Input/Input';
import { Select } from '../../Common/Select/Select';

/* configs */
import api from '../../../configs/api';

/* types */
import { IVehicle } from '../../../types/Vehicle';
import { VehicleType } from '../../../types/VehicleType';

/* props */
interface Props {
  vehicle?: IVehicle;
  handleSubmit: (data: IVehicle) => void;
}

export const VehicleForm = (props: Props) => {
  const { vehicle } = props;

  const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>([]);
  const [vehicleType, setVehicleType] = useState<string>();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const data = Object.fromEntries(
      new FormData(form).entries()
    ) as unknown as IVehicle;

    if (vehicle) data.id = vehicle.id;

    props.handleSubmit(data);
  };

  /* get vehicle types */
  useEffect(() => {
    api
      .get(`/vehicle_types`)
      .then((response) => setVehicleTypes(response.data));
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
      method="post"
      className="flex flex-col gap-4 mt-8"
    >
      {/* type */}
      <Select
        name="type"
        label="Type"
        value={vehicleType || vehicle?.type}
        options={vehicleTypes.map((type) => {
          return {
            label: type.name,
            value: type.id
          };
        })}
        onChange={(e) => setVehicleType(e.target.value)}
      />
      {/* make */}
      <Input
        name="make"
        type="text"
        label="Make"
        defaultValue={vehicle?.make}
      />
      {/* model */}
      <Input
        name="model"
        type="text"
        label="Model"
        defaultValue={vehicle?.model}
      />
      {/* year */}
      <Input
        name="year"
        type="number"
        label="Year"
        defaultValue={vehicle?.year}
      />
      {/* color */}
      <Input
        name="color"
        type="text"
        label="Color"
        defaultValue={vehicle?.color}
      />
      {/* registration */}
      <Input
        name="registration"
        type="text"
        label="Registration"
        defaultValue={vehicle?.registration}
      />
      {/* displacement */}
      <Input
        name="displacement"
        type="number"
        label="Displacement"
        defaultValue={vehicle?.displacement}
      />
      {/* submit */}
      <div className="flex justify-end mt-4">
        <button type="submit" className="btn btn-sm btn-primary">
          Submit form
        </button>
      </div>
    </form>
  );
};
