import { cloneElement, ReactNode, useEffect, useState } from 'react';

interface Props {
  id: string;
  title: string;
  trigger?: JSX.Element;
  action: () => void;
  children: ReactNode;
}

export const Modal = (props: Props) => {
  const { id, title, trigger, children } = props;

  const [modal, setModal] = useState<HTMLDialogElement | null>(null);

  useEffect(() => {
    setModal(document.getElementById(id) as HTMLDialogElement);
  }, [id]);

  const Trigger = () => {
    if (!trigger) return null;
    return cloneElement(trigger, { onClick: () => modal && modal.showModal() });
  };

  return (
    <>
      <Trigger />

      {/* modal */}
      <dialog id={id} className="modal">
        <div className="modal-box">
          {/* close button on modal */}
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          {/* title */}
          <h3 className="font-bold text-lg">{title}</h3>
          {/* content */}
          <p className="py-4">{children}</p>
          {/* actions */}
          <div className="flex gap-2 justify-end mt-1">
            {/* cancel */}
            <button
              className="btn btn-sm"
              onClick={() => {
                modal?.close();
              }}
            >
              Cancel
            </button>
            {/* confirm */}
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                props.action();
                modal?.close();
              }}
            >
              Confirm
            </button>
          </div>
        </div>
        {/* close when clicked outside */}
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
};
