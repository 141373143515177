import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';

/* components */
import { CourseForm } from '../../../../Forms/CourseForm/CourseForm';
import { Drawer } from '../../../../Common/Drawer/Drawer';
import { Modal } from '../../../../Common/Modal/Modal';
import { Card } from '../../../../Common/Card/Card';
import { Vehicle } from './Vehicle/Vehicle';
import { VehicleSelectForm } from '../../../../Forms/VehicleSelectForm/VehicleSelectForm';

/* configs */
import api from '../../../../../configs/api';

/* icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAdd,
  faExclamation,
  faPencil
} from '@fortawesome/free-solid-svg-icons';

/* types */
import { Course as CourseType } from '../../../../../types/Course';
import { IVehicle } from '../../../../../types/Vehicle';
import { License } from '../../../../../types/License';

interface Props {
  course: CourseType;
  onChange: () => void;
}

export const Course = (props: Props) => {
  const [course, setCourse] = useState<CourseType>(props.course);
  const [requiredLicense, setRequiredLicense] = useState<License>();
  const [obtainedLicense, setObtainedLicense] = useState<License>();
  const [vehicles, setVehicles] = useState<IVehicle[]>([]);

  /* update */
  const updateCourse = (course: CourseType) => {
    api
      .put(`/courses/${props.course.id}`, course)
      .then((response) => {
        setCourse(response.data);
        toast.success('Course updated!');
      })
      .then(() => props.onChange());
  };

  /* delete */
  const deleteCourse = () => {
    api
      .put(`/courses/${course.id}`, { ...course, deleted: true })
      .then((response) => {
        setCourse(response.data);
        toast.success('Course deleted!');
      })
      .then(() => props.onChange());
  };

  /* restore */
  const restoreCourse = () => {
    api
      .put(`/courses/${course.id}`, { ...course, deleted: false })
      .then((response) => {
        setCourse(response.data);
        toast.success('Course restored!');
      })
      .then(() => props.onChange());
  };

  /* get vehicles for this course */
  const getVehicles = () => {
    api
      .get(`/courses/${course.id}/vehicles`)
      .then((response) => setVehicles(response.data));
  };

  /* add vehicle to course */
  const addVehicleToCourse = (vehicle: IVehicle) => {
    api
      .post(`/courses/${course.id}/vehicles/${vehicle.id}`)
      .then(() => getVehicles());
  };

  /* get licenses */
  useEffect(() => {
    /* required license */
    if (course.license_required)
      api
        .get(`/licenses/${course.license_required}`)
        .then((response) => setRequiredLicense(response.data));

    /* obtained license */
    if (course.license_obtained)
      api
        .get(`/licenses/${course.license_obtained}`)
        .then((response) => setObtainedLicense(response.data));

    getVehicles();
  }, [course]); // eslint-disable-line react-hooks/exhaustive-deps

  /* card actions */
  const Actions = () => {
    return (
      <div className="flex gap-2">
        {/* delete course */}
        {!course.deleted && (
          <Modal
            id={`delete-course-${course.id}`}
            title="Delete Course"
            trigger={<button className="btn btn-sm">Delete</button>}
            action={deleteCourse}
          >
            Are you sure you want to delete this course?
          </Modal>
        )}
        {/* edit course */}
        {!course.deleted && (
          <Drawer
            id={`edit-course-${course.id}`}
            title="Edit Course"
            trigger={
              <label
                htmlFor={`edit-course-${course.id}`}
                className="drawer-button btn btn-sm"
              >
                <span className="flex gap-2">
                  <FontAwesomeIcon icon={faPencil} />
                  Edit
                </span>
              </label>
            }
          >
            <CourseForm
              course={course}
              handleSubmit={(course) => updateCourse(course)}
            />
          </Drawer>
        )}
        {/* add vehicle to coure */}
        {!course.deleted && (
          <Drawer
            id={`add-course-vehicle-${course.id}`}
            title={`${course.name} - Add Vehicle`}
            trigger={
              <label
                htmlFor={`add-course-vehicle-${course.id}`}
                className="drawer-button btn btn-sm"
              >
                <span className="flex gap-2">
                  <FontAwesomeIcon icon={faAdd} />
                  Vehicle
                </span>
              </label>
            }
          >
            <VehicleSelectForm
              handleSubmit={(vehicle) => addVehicleToCourse(vehicle)}
            />
          </Drawer>
        )}
        {/* restore course */}
        {course.deleted && (
          <Modal
            id={`restore-course-${course.id}`}
            title="Restore Course"
            trigger={<button className="btn btn-sm">Restore</button>}
            action={restoreCourse}
          >
            Are you sure you want to restore this course?
          </Modal>
        )}
      </div>
    );
  };

  return (
    <Card
      image="placeholder"
      title={course.name}
      actions={<Actions />}
      className={classNames(course.deleted && 'bg-neutral-100')}
    >
      <div className="flex flex-col gap-4 h-full text-sm">
        {/* description */}
        <div className="flex flex-col gap-2">
          <div>{course.description}</div>
        </div>
        <div className="flex flex-col gap-1">
          {/* license required */}
          <div className="flex justify-between items-center">
            <div className="flex gap-2">
              <div>License Required:</div>
              <strong>{requiredLicense?.name || 'None'}</strong>
            </div>
            {/* error badge */}
            {requiredLicense?.deleted && (
              <span className="badge badge-sm badge-error mx-2">
                <FontAwesomeIcon
                  icon={faExclamation}
                  className="text-neutral-000"
                />
              </span>
            )}
          </div>
          {/* license obtained */}
          <div className="flex justify-between items-center">
            <div className="flex gap-2">
              <div>License Obtained:</div>
              <strong>{obtainedLicense?.name || 'None'}</strong>
            </div>
            {/* error badge */}
            {obtainedLicense?.deleted && (
              <span className="badge badge-sm badge-error mx-2">
                <FontAwesomeIcon
                  icon={faExclamation}
                  className="text-neutral-000"
                />
              </span>
            )}
          </div>
          {/* number of days */}
          <div className="flex gap-2">
            <div>Number of days:</div>
            <strong>{course.days}</strong>
          </div>
          {/* capacity */}
          <div className="flex gap-2">
            <div>Capacity:</div>
            <strong>{course.capacity}</strong>
          </div>
          {/* price */}
          <div className="flex gap-2">
            <div>Price:</div>
            <strong>£{course.price}</strong>
          </div>
        </div>
        {/* vehicles */}
        <div className="font-bold">Available Vehicles</div>
        <div className="flex flex-col gap-2 pb-2 flex-grow">
          {vehicles.map((vehicle) => (
            <Vehicle
              key={vehicle.id}
              vehicle={vehicle}
              course_id={course.id}
              onDelete={getVehicles}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};
