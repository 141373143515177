import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

/* configs */
import api from '../../../../../configs/api';

/* redux */
import { startLoading, stopLoading } from '../../../../../redux/loading';

/* types */
import { Location } from '../../../../../types/Location';

interface Props {
  handleSubmit: (data: Location) => void;
}

export const LocationSelection = (props: Props) => {
  const dispatch = useDispatch();

  const [locations, setLocations] = useState<Location[]>([]);
  const [location, setLocation] = useState<Location>();

  const handleSubmit = () => {
    if (!location) return console.log('Location is required.');

    props.handleSubmit(location);
  };

  useEffect(() => {
    /* get the data available for selection */
    dispatch(startLoading());

    /* locations */
    api.get(`/locations`).then((response) => {
      setLocations(response.data);
      dispatch(stopLoading());
    });
  }, [dispatch]);

  return (
    <form onSubmit={handleSubmit} method="post" className="flex flex-col gap-4">
      {/* location */}
      <label htmlFor="location">Which location works best for you?</label>
      <div className="flex gap-4">
        {locations.map((loc) => (
          <div
            key={loc.id}
            onClick={() =>
              loc.id === location?.id
                ? setLocation(undefined)
                : setLocation(loc)
            }
            className={classNames(
              'border border-neutral-200 rounded p-4 cursor-pointer',
              loc.id === location?.id && 'border-primary'
            )}
          >
            {loc.name}
          </div>
        ))}
      </div>
      {/* actions */}
      <div className="flex justify-end">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          disabled={!location}
        >
          Next
        </button>
      </div>
    </form>
  );
};
