import { FormEvent, useEffect, useState } from 'react';
import classNames from 'classnames';

/* components */
import { Placeholder } from '../../Common/Placeholder/Placeholder';

/* api */
import { getVehicles } from '../../../api/vehicles';

/* types */
import { IVehicle } from '../../../types/Vehicle';

/* props */
interface Props {
  handleSubmit: (data: IVehicle) => void;
}

export const VehicleSelectForm = (props: Props) => {
  const [selectedVehicle, setSelectedVehicle] = useState<IVehicle>();
  const [vehicles, setVehicles] = useState<IVehicle[]>([]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (selectedVehicle) {
      props.handleSubmit(selectedVehicle);
    }
  };

  useEffect(() => {
    getVehicles().then((vehicles) => setVehicles(vehicles));
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
      method="post"
      className="flex flex-col gap-4 mt-8"
    >
      {/* vehicles */}
      {vehicles.map((vehicle) => (
        <div
          key={vehicle.id}
          className={classNames(
            'flex justify-between items-center border',
            'border-neutral-200 p-2 rounded cursor-pointer',
            selectedVehicle?.id === vehicle.id && 'border-primary'
          )}
          onClick={() => setSelectedVehicle(vehicle)}
        >
          <div className="flex items-center gap-4">
            {/* image */}
            <div className="w-10 h-10 rounded flex items-center overflow-hidden">
              <Placeholder />
            </div>
            {/* make, model and registration */}
            <div className="flex flex-col">
              <div className="text-xs font-medium">{`${vehicle.make} ${vehicle.model}`}</div>
              <div className="text-xs">{vehicle.registration}</div>
            </div>
          </div>
        </div>
      ))}
      {/* submit */}
      <div className="flex justify-end mt-4">
        <button type="submit" className="btn btn-sm btn-primary">
          Submit form
        </button>
      </div>
    </form>
  );
};
