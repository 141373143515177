import axios from 'axios';

/* configs */
import { loginRequest } from './auth';
import { instance } from './auth';

const graph = axios.create({
  baseURL: 'https://graph.microsoft.com/v1.0'
});

/* attach the graph access token onto each request */
graph.interceptors.request.use(async (config) => {
  const request = {
    ...loginRequest,
    account: instance.getAllAccounts()[0]
  };

  const token = await instance
    .acquireTokenSilent(request)
    .then((response) => response.accessToken)
    .catch(() =>
      instance
        .acquireTokenPopup(request)
        .then((response) => response.accessToken)
    );

  const bearer = `Bearer ${token}`;

  config.headers.Authorization = bearer;

  return config;
});

export default graph;
