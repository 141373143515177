import { HeaderGroup, flexRender } from '@tanstack/react-table';

/* icons */
import {
  faSortAlphaUp,
  faSortAlphaDown
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  headerGroup: HeaderGroup<any>; // eslint-disable-line
}

export const TableHeaderRow = (props: Props) => {
  const { headerGroup } = props;

  return (
    <tr key={headerGroup.id}>
      {headerGroup.headers.map((header) => {
        return (
          <th key={header.id} colSpan={header.colSpan}>
            {header.isPlaceholder ? null : (
              <div
                {...{
                  className: header.column.getCanSort()
                    ? 'cursor-pointer select-none'
                    : '',
                  onClick: header.column.getToggleSortingHandler()
                }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
                {{
                  asc: <FontAwesomeIcon icon={faSortAlphaUp} />,
                  desc: <FontAwesomeIcon icon={faSortAlphaDown} />
                }[header.column.getIsSorted() as string] ?? null}
              </div>
            )}
          </th>
        );
      })}
    </tr>
  );
};
