import { useMsal } from '@azure/msal-react';

/* components */
import { Placeholder } from '../../../Common/Placeholder/Placeholder';
import { NavLink } from 'react-router-dom';

export const User = () => {
  const { instance } = useMsal();

  /* log the user out of the msal instance */
  const logout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/'
    });
  };

  return (
    <div className="dropdown dropdown-end">
      <div
        tabIndex={0}
        role="button"
        className="btn btn-ghost btn-circle avatar"
      >
        <div className="w-10 rounded-full">
          <Placeholder />
        </div>
      </div>

      {/* menu */}
      <ul
        tabIndex={0}
        className="mt-3 z-[1] p-2 shadow menu dropdown-content bg-neutral-000
                        rounded-box w-52"
      >
        {/* profile */}
        <li>
          <NavLink to="/profile">Profile</NavLink>
        </li>
        {/* logout */}
        <li>
          <button onClick={logout}>Logout</button>
        </li>
      </ul>
    </div>
  );
};
