import { FormEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

/* config */
import api from '../../../../../configs/api';

/* redux */
import { startLoading, stopLoading } from '../../../../../redux/loading';

/* types */
import { Customer } from '../../../../../types/Customer';
import { Experience } from '../../../../../types/Experience';

interface Props {
  customer: Customer;
  handleSubmit: (data: Customer) => void;
}

export const ExperienceDetails = (props: Props) => {
  const { customer } = props;

  const dispatch = useDispatch();

  const [experiences, setExperiences] = useState<Experience[]>([]);
  const [experience, setExperience] = useState<Experience>();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    /* validate a level of experience was selected */
    if (!experience) return console.log('Experience is required.');

    /* update the customer with the selected experience */
    api
      .put(`/customers/${customer.id}`, {
        ...customer,
        experience_id: experience.id
      })
      .then((response) => props.handleSubmit(response.data))
      .catch((error) => toast.error(error.message));
  };

  /* get the data available for selection */
  useEffect(() => {
    dispatch(startLoading());

    /* experiences */
    api.get(`/experiences`).then((response) => {
      setExperiences(response.data);
      dispatch(stopLoading());
    });
  }, []); // eslint-disable-line

  return (
    <form onSubmit={handleSubmit} method="post" className="flex flex-col gap-4">
      {/* experience */}
      <label htmlFor="experience">
        How would you rate your level of experience?
      </label>
      <div className="flex gap-4">
        {experiences.map((exp) => (
          <div
            key={exp.id}
            onClick={() => setExperience(exp)}
            className={classNames(
              'border border-neutral-200 rounded p-4 cursor-pointer',
              'flex flex-col gap-2',
              exp.id === experience?.id && 'border-primary'
            )}
          >
            <div className="font-bold">{exp.name}</div>
            <div>{exp.description}</div>
          </div>
        ))}
      </div>
      {/* actions */}
      <div className="flex justify-end">
        <button type="submit" className="btn btn-sm btn-primary">
          Next
        </button>
      </div>
    </form>
  );
};
