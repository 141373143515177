/* icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';

export const Placeholder = () => {
  return (
    <div
      className="w-full h-full bg-neutral-200 flex items-center 
                justify-center"
    >
      <FontAwesomeIcon icon={faImage} className="text-neutral-400 h-[40%]" />
    </div>
  );
};
