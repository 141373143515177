/* css */
import './Loading.css';

export const Loading = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <span className="loader"></span>
    </div>
  );
};
