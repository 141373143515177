import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';

/* components */
import { CustomerForm } from '../../../Forms/CustomerForm/CustomerForm';
import { Drawer } from '../../../Common/Drawer/Drawer';
import { Heading } from '../../../Common/Heading/Heading';
import { Table } from '../../../Common/Table/Table';
import { TableCheckbox } from '../../../Common/Table/TableCheckbox/TableCheckbox';

/* configs */
import api from '../../../../configs/api';

/* redux */
import { startLoading, stopLoading } from '../../../../redux/loading';

/* types */
import { Customer } from '../../../../types/Customer';

export const Customers = () => {
  const dispatch = useDispatch();

  const [customers, setCustomers] = useState<Customer[]>([]);

  /* get customers */
  const getCustomers = () => {
    dispatch(startLoading());

    api
      .get(`/customers`)
      .then((response) => setCustomers(response.data))
      .then(() => dispatch(stopLoading()));
  };

  /* create customer */
  const createCustomer = (customer: Customer) => {
    api
      .post(`/customers`, customer)
      .then(() => {
        document.getElementById('new-customer')?.click();
        toast.success('Customer created!');
        getCustomers();
      })
      .catch((error) => toast.error(error.message));
  };

  /* update customer */
  const updateCustomer = (customer: Customer) => {
    api
      .put(`/customers/${customer.id}`, customer)
      .then(() => {
        document.getElementById('edit-customer')?.click();
        toast.success('Customer updated!');
        getCustomers();
      })
      .catch((error) => toast.error(error.message));
  };

  useEffect(() => {
    getCustomers();
  }, []); // eslint-disable-line

  const columnHelper = createColumnHelper<Customer>();

  const columns = [
    columnHelper.display({
      id: 'select',
      header: ({ table }) => (
        <TableCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler()
          }}
        />
      ),
      cell: ({ row }) => (
        <TableCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler()
          }}
        />
      )
    }),
    columnHelper.accessor('first_name', {
      header: () => <span>First Name</span>,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('last_name', {
      header: () => <span>Last Name</span>,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('email', {
      header: () => <span>Email</span>,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('phone', {
      header: () => <span>Phone</span>,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('date_of_birth', {
      header: () => <span>Date of Birth</span>,
      cell: (info) => moment(info.getValue()).format('DD/MM/YYYY')
    })
  ];

  return (
    <>
      <Heading title="Customers">
        {/* new customer form */}
        <Drawer
          id="new-customer"
          title="New Customer"
          trigger={
            <label
              htmlFor="new-customer"
              className="drawer-button btn btn-primary btn-sm"
            >
              New
            </label>
          }
        >
          <CustomerForm handleSubmit={(customer) => createCustomer(customer)} />
        </Drawer>
      </Heading>
      {/* customers */}
      <Table
        columns={columns}
        data={customers.filter((customer) => !customer.deleted)}
        drawer={{
          id: 'edit-customer',
          name: 'Edit Customer',
          content: (
            <CustomerForm
              handleSubmit={(customer) => updateCustomer(customer)}
            />
          )
        }}
        enableRowSelection
        fixedPagination
      />
    </>
  );
};
