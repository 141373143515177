import { toast } from 'react-toastify';

/* configs */
import api from '../configs/api';

/* types */
import { IVehicle } from '../types/Vehicle';

/**
 * Get all Vehicles
 * @returns Vehicle[]
 */
export const getVehicles = async (): Promise<IVehicle[]> => {
  return api
    .get(`/vehicles`)
    .then((response) => response.data)
    .catch((error) => {
      toast.error(error.message);
    });
};

/**
 * Get a Vehicle by ID
 * @param id - vehicle id
 * @returns Vehicle
 */
export const getVehicle = async (id: string): Promise<IVehicle> => {
  return api
    .get(`/vehicles/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      toast.error(error.message);
    });
};

/**
 * Create a Vehicle
 * @param vehicle - Vehicle
 * @returns Vehicle
 */
export const createVehicle = async (vehicle: IVehicle): Promise<IVehicle> => {
  return api
    .post(`/vehicles`, vehicle)
    .then((response) => {
      if (response.data.statusCode === '500') {
        throw new Error("Vehicle couldn't be created!");
      }

      toast.success('Vehicle created!');
      return response.data;
    })
    .catch((error) => {
      toast.error(error.message);
      return vehicle;
    });
};

/**
 * Update a Vehicle
 * @param vehicle - Vehicle
 * @returns Vehicle
 */
export const updateVehicle = async (vehicle: IVehicle): Promise<IVehicle> => {
  return api
    .put(`/vehicles/${vehicle.id}`, vehicle)
    .then((response) => {
      if (response.data.statusCode === '500') {
        throw new Error("Vehicle couldn't be updated!");
      }

      toast.success('Vehicle updated!');
      return response.data;
    })
    .catch((error) => {
      toast.error(error.message);
    });
};

/**
 * Delete a Vehicle
 * @param id - vehicle id
 * @returns Vehicle
 */
export const deleteVehicle = async (vehicle: IVehicle): Promise<IVehicle> => {
  return api
    .put(`/vehicles/${vehicle.id}`, { ...vehicle, deleted: true })
    .then((response) => {
      toast.success('Vehicle deleted!');
      return response.data;
    })
    .catch((error) => {
      toast.error(error.message);
    });
};

/**
 * Restore a Vehicle
 * @param id - vehicle id
 * @returns Vehicle
 */
export const restoreVehicle = async (vehicle: IVehicle): Promise<IVehicle> => {
  return api
    .put(`/vehicles/${vehicle.id}`, { ...vehicle, deleted: false })
    .then((response) => {
      toast.success('Vehicle restored!');
      return response.data;
    })
    .catch((error) => {
      toast.error(error.message);
    });
};

/**
 * Permanently delete a Vehicle
 * @param id - vehicle id
 * @returns void
 */
export const permanentlyDeleteVehicle = async (id: string): Promise<void> => {
  return api
    .delete(`/vehicles/${id}`)
    .then(() => {
      toast.success('Vehicle deleted!');
    })
    .catch((error) => {
      toast.error(error.message);
    });
};
