import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

/* configs */
import api from '../../../../../configs/api';

/* redux */
import { startLoading, stopLoading } from '../../../../../redux/loading';

/* types */
import { Booking, BookingDetails } from '../../../../../types/Booking';
import { Heading } from '../../../../Common/Heading/Heading';

interface Props {
  booking: Booking;
}

export const BookingConfirmation = (props: Props) => {
  const [booking, setBooking] = useState<BookingDetails>();

  const dispatch = useDispatch();

  /* get the booking details */
  useEffect(() => {
    dispatch(startLoading());

    api.get(`/bookings/${props.booking.id}`).then((response) => {
      setBooking(response.data);
      dispatch(stopLoading());
    });
  }, [props.booking, dispatch]);

  return (
    <>
      <Heading title="Booking Confirmation" />
      <div className="flex flex-col gap-2">
        <div>Name: {booking?.customer_name}</div>
        <div>Course: {booking?.course_name}</div>
        <div>Location: {booking?.location_name}</div>
        <div>Date: {moment(booking?.date).format('Do MMMM YYYY')}</div>
      </div>
    </>
  );
};
