import { useEffect, useState } from 'react';
import moment from 'moment';
import { Calendar as ReactCalendar } from 'react-calendar';
import { useDispatch } from 'react-redux';
import { createColumnHelper } from '@tanstack/react-table';
import { toast } from 'react-toastify';
import classNames from 'classnames';

/* components */
import { Card } from '../../../Common/Card/Card';
import { Heading } from '../../../Common/Heading/Heading';
import { CalendarTile } from './CalendarTile/CalendarTile';
import { CalendarCourse } from './CalendarCourse/CalendarCourse';
import { Drawer } from '../../../Common/Drawer/Drawer';
import { CourseSelectionForm } from '../../../Forms/CourseSelectionForm/CourseSelectionForm';
import { Table } from '../../../Common/Table/Table';
import { BookingForm } from '../../../Forms/BookingForm/BookingForm';

/* configs */
import api from '../../../../configs/api';

/* redux */
import { startLoading, stopLoading } from '../../../../redux/loading';

/* types */
import { CourseDate } from '../../../../types/Course';
import { Booking, BookingDetails } from '../../../../types/Booking';

export const Calendar = () => {
  const dispatch = useDispatch();

  const [activeStartDate, setActiveStartDate] = useState<Date>(new Date());
  const [date, setDate] = useState<Date>(new Date());

  const [courses, setCourses] = useState<CourseDate[]>([]);
  const [bookings, setBookings] = useState<BookingDetails[]>([]);

  const columnHelper = createColumnHelper<BookingDetails>();

  /* booking table columns */
  const columns = [
    columnHelper.accessor('date', {
      header: () => <span>Date</span>,
      cell: (info) => moment(info.getValue()).format('DD/MM/YYYY')
    }),
    columnHelper.accessor('customer_name', {
      header: () => <span>Customer</span>,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor(
      (row) => (
        <span className={classNames(row.course_deleted && 'text-error')}>
          {row.course_name}
        </span>
      ),
      {
        id: 'course_name',
        header: () => <span>Course</span>,
        cell: (info) => info.getValue()
      }
    ),
    columnHelper.accessor(
      (row) => (
        <span className={classNames(row.location_deleted && 'text-error')}>
          {row.location_name}
        </span>
      ),
      {
        id: 'location_name',
        header: () => <span>Location</span>,
        cell: (info) => info.getValue()
      }
    ),
    columnHelper.accessor(
      (row) => (
        <span className={classNames(row.vehicle_deleted && 'text-error')}>
          {row.vehicle_name}
        </span>
      ),
      {
        id: 'vehicle_name',
        header: () => <span>Vehicle</span>,
        cell: (info) => info.getValue()
      }
    )
  ];

  /* get the details of each day of the displayed month */
  const getCalendar = async () => {
    dispatch(startLoading());

    const startDate = moment(activeStartDate)
      .startOf('month')
      .format('YYYY-MM-DD');

    const endDate = moment(activeStartDate).endOf('month').format('YYYY-MM-DD');

    const promises: Promise<void>[] = [];

    promises.push(getCourses(startDate, endDate));
    promises.push(getBookings(startDate, endDate));

    await Promise.all(promises).then(() => dispatch(stopLoading()));
  };

  /* get the courses for the visible date range */
  const getCourses = async (startDate: string, endDate: string) => {
    api
      .get(`/calendar/courses/dates?startDate=${startDate}&endDate=${endDate}`)
      .then((response) => setCourses(response.data));
  };

  /* get the bookings for the visible date range */
  const getBookings = async (startDate: string, endDate: string) => {
    api
      .get(`/calendar/bookings/dates?startDate=${startDate}&endDate=${endDate}`)
      .then((response) =>
        setBookings(
          response.data.filter(
            (booking: Booking) => !booking.deleted && !booking.cancelled
          )
        )
      );
  };

  /* update booking */
  const updateBooking = (booking: Booking) => {
    api
      .put(`/bookings/${booking.id}`, booking)
      .then(() => {
        toast.success('Booking updated!');
        getCalendar();
      })
      .catch(() => toast.error('Error updating booking.'));
  };

  /* get the calendar data */
  useEffect(() => {
    getCalendar();
  }, [activeStartDate]); // eslint-disable-line

  return (
    <>
      <Heading title="Calendar">
        {/* selected date */}
        {moment(date).format('dddd, Do MMMM YYYY')}
      </Heading>
      {/* add course */}
      <Drawer id="new-calendar-course" title="Add Course">
        <CourseSelectionForm date={date} onUpdate={getCalendar} />
      </Drawer>
      {/* page content */}
      <div className="flex flex-col gap-4">
        <div className="grid gap-4 xl:grid-cols-3">
          {/* calendar */}
          <Card className="xl:col-span-2">
            <ReactCalendar
              onChange={(value) => value && setDate(new Date(value.toString()))}
              value={date}
              minDetail="year"
              tileContent={({ date }) => {
                return (
                  <CalendarTile
                    date={date}
                    courses={courses}
                    bookings={bookings}
                  />
                );
              }}
              onActiveStartDateChange={({ activeStartDate }) =>
                activeStartDate && setActiveStartDate(activeStartDate)
              }
            />
          </Card>
          {/* day details */}
          <Card>
            <div className="flex flex-col gap-8">
              {/* courses */}
              <div className="flex justify-between items-center">
                <span className="text-lg">Courses</span>
                <button
                  onClick={() =>
                    document.getElementById('new-calendar-course')?.click()
                  }
                  className="btn btn-sm btn-primary"
                >
                  Add Course
                </button>
              </div>
              <div className="flex flex-col gap-4 text-sm">
                {courses
                  .filter(
                    (course) =>
                      moment(course.date).format('YYYY-MM-DD') ===
                      moment(date).format('YYYY-MM-DD')
                  )
                  .map((course, index) => (
                    <CalendarCourse
                      key={index}
                      course={course}
                      onUpdate={getCalendar} //TODO don't get whole calendar
                    />
                  ))}
                {/* no courses */}
                {courses.length === 0 && <div>There are no courses.</div>}
              </div>
            </div>
          </Card>
        </div>
        {/* bookings */}
        <Card className="flex flex-col gap-4">
          <span className="text-lg">Bookings</span>
          <Table
            columns={columns}
            data={bookings.filter(
              (booking) =>
                moment(booking.date).format('YYYY-MM-DD') ===
                moment(date).format('YYYY-MM-DD')
            )}
            drawer={{
              id: 'edit-booking',
              name: 'Edit Booking',
              content: (
                <BookingForm
                  handleSubmit={(booking) => updateBooking(booking)}
                />
              )
            }}
          />
        </Card>
      </div>
    </>
  );
};
