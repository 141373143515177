import { ReactNode } from 'react';

/* icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface Props {
  id: string;
  title: string;
  trigger?: ReactNode;
  children: ReactNode;
}

export const Drawer = (props: Props) => {
  const { id, title, trigger, children } = props;

  /* close the drawer */
  const close = () => {
    document.getElementById(id)?.click();
  };

  return (
    <div className="drawer drawer-end">
      <input id={id} type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">
        {/* trigger */}
        {trigger}
      </div>
      <div className="drawer-side overflow-x-hidden z-10">
        {/* overlay */}
        <label
          htmlFor={id}
          aria-label="close sidebar"
          className="drawer-overlay"
        />
        <ul className="menu p-4 pt-20 min-h-full bg-neutral-000 w-screen xs:w-[475px]">
          {/* header */}
          <div
            className="flex justify-between items-center border-b 
                    border-neutral-200 pb-4"
          >
            <div className="font-medium">{title}</div>
            <button className="btn btn-square btn-sm" onClick={close}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          {/* content */}
          <div>{children}</div>
        </ul>
      </div>
    </div>
  );
};
