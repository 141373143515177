import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

/* components */
import { Vehicle } from './Vehicle/Vehicle';
import { VehicleForm } from '../../../Forms/VehicleForm/VehicleForm';
import { Drawer } from '../../../Common/Drawer/Drawer';
import { Heading } from '../../../Common/Heading/Heading';

/* api */
import { createVehicle, getVehicles } from '../../../../api/vehicles';

/* redux */
import { startLoading, stopLoading } from '../../../../redux/loading';

/* types */
import { IVehicle } from '../../../../types/Vehicle';

export const Vehicles = () => {
  const dispatch = useDispatch();

  const [vehicles, setVehicles] = useState<IVehicle[]>([]);

  useEffect(() => {
    dispatch(startLoading());

    getVehicles().then((vehicles) => {
      setVehicles(vehicles);
      dispatch(stopLoading());
    });
  }, [dispatch]);

  return (
    <>
      <Heading title="Vehicles">
        {/* new vehicle form */}
        <Drawer
          id="new-vehicle"
          title="New Vehicle"
          trigger={
            <label
              htmlFor="new-vehicle"
              className="drawer-button btn btn-primary btn-sm"
            >
              New
            </label>
          }
        >
          <VehicleForm
            handleSubmit={(vehicle) =>
              createVehicle(vehicle).then(() => {
                setVehicles([vehicle, ...vehicles]);
                document.getElementById('new-vehicle')?.click();
              })
            }
          />
        </Drawer>
      </Heading>
      {/* vehicles */}
      <div
        className="grid grid-cols-1 xs:grid-cols-2 
                  lg:grid-cols-3 xl:grid-cols-4 gap-4"
      >
        {vehicles?.map((vehicle) => (
          <Vehicle vehicle={vehicle} key={vehicle.id} />
        ))}
      </div>
    </>
  );
};
