import { useState } from 'react';

/* components */
import { CustomerDetails } from './CustomerDetails/CustomerDetails';
import { ExperienceDetails } from './ExperienceDetails/ExperienceDetails';
import { LocationSelection } from './LocationSelection/LocationSelection';
import { CourseSelection } from './CourseSelection/CourseSelection';
import { DateSelection } from './DateSelection/DateSelection';
import { CreateBooking } from './CreateBooking/CreateBooking';
import { BookingConfirmation } from './BookingConfirmation/BookingConfirmation';

/* types */
import { Customer } from '../../../../types/Customer';
import { Location } from '../../../../types/Location';
import { Course } from '../../../../types/Course';
import { Booking } from '../../../../types/Booking';

export const Book = () => {
  const [customer, setCustomer] = useState<Customer>();
  const [location, setLocation] = useState<Location>();
  const [course, setCourse] = useState<Course>();
  const [date, setDate] = useState<Date>();
  const [booking, setBooking] = useState<Booking>();

  return (
    <>
      {!customer ? (
        /* customer details */
        <CustomerDetails handleSubmit={(customer) => setCustomer(customer)} />
      ) : !customer.experience_id ? (
        /* experience details */
        <ExperienceDetails
          customer={customer}
          handleSubmit={(customer) => setCustomer(customer)}
        />
      ) : !location ? (
        /* location selection */
        <LocationSelection handleSubmit={(location) => setLocation(location)} />
      ) : !course ? (
        /* course selection */
        <CourseSelection
          customer={customer}
          handleSubmit={(course) => setCourse(course)}
        />
      ) : !date ? (
        /* date selection */
        <DateSelection
          handleSubmit={(date) => setDate(date)}
          location={location}
          course={course}
        />
      ) : customer && location && course && date && !booking ? (
        /* review details */
        <CreateBooking
          customer={customer}
          course={course}
          location={location}
          date={date}
          handleSubmit={(booking) => setBooking(booking)}
        />
      ) : booking ? (
        /* booking confirmation */
        <BookingConfirmation booking={booking} />
      ) : (
        /* error */
        <div>Something went wrong.</div>
      )}
    </>
  );
};
