import { ChangeEvent } from 'react';

interface Props {
  name: string;
  type: string;
  label?: string;
  step?: string;
  max?: string;
  value?: string | number;
  defaultValue?: string | number;
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => unknown;
}

export const Input = (props: Props) => {
  const { label, name, type, step, max, placeholder, value, defaultValue } =
    props;

  return (
    <label className="form-control w-full max-w-xs" htmlFor={name}>
      {/* label */}
      {label && (
        <div className="label">
          <span className="label-text">{label}</span>
        </div>
      )}
      {/* input */}
      <input
        className="input input-bordered input-sm w-full max-w-xs"
        placeholder={placeholder || ' '}
        type={type}
        name={name}
        step={step}
        max={max}
        value={value}
        defaultValue={defaultValue}
        onChange={props.onChange}
      />
    </label>
  );
};
