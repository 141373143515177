import { Calendar as ReactCalendar } from 'react-calendar';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

/* redux */
import { startLoading, stopLoading } from '../../../../../redux/loading';

/* config */
import api from '../../../../../configs/api';

/* types */
import { Course } from '../../../../../types/Course';
import { Location } from '../../../../../types/Location';
import { ICourseAvailability } from '../../../../../types/Availability';

interface Props {
  course: Course;
  location?: Location;
  handleSubmit: (data: Date) => void;
}

const CalendarTile = ({
  date,
  availability
}: {
  date: Date;
  availability: ICourseAvailability[];
}) => {
  const selectable = availability.some(
    (a) =>
      moment(a.date).format('YYYY-MM-DD') ===
        moment(date).format('YYYY-MM-DD') && a.vacancies > 0
  );

  return (
    <div className="h-12 py-4 font-normal">
      {selectable && availability[0].course_name}
    </div>
  );
};

export const DateSelection = (props: Props) => {
  const { course } = props;

  const dispatch = useDispatch();

  const [activeStartDate, setActiveStartDate] = useState<Date>();
  const [date, setDate] = useState<Date>();

  const [courseAvailability, setCourseAvailability] = useState<
    ICourseAvailability[]
  >([]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    /* validate a date was selected */
    if (!date) return console.log('Date is required.');

    props.handleSubmit(date);
  };

  /* get the details of each day of the displayed month */
  const getCalendar = async () => {
    dispatch(startLoading());

    const startDate = moment(activeStartDate)
      .startOf('month')
      .format('YYYY-MM-DD');

    const endDate = moment(activeStartDate).endOf('month').format('YYYY-MM-DD');

    api
      .get(
        `/availability/courses/${course.id}/dates?startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => {
        console.log(response.data);
        setCourseAvailability(response.data);
        dispatch(stopLoading());
      });
  };

  /* get the calendar data */
  useEffect(() => {
    getCalendar();
  }, [activeStartDate]); // eslint-disable-line

  return (
    <form onSubmit={handleSubmit} method="post" className="flex flex-col gap-4">
      <ReactCalendar
        onChange={(value) => value && setDate(new Date(value.toString()))}
        value={date}
        minDetail="year"
        tileContent={({ date }) => {
          return <CalendarTile date={date} availability={courseAvailability} />;
        }}
        tileDisabled={({ date }) => {
          /* disable tiles that have no availability or are in the past */
          return (
            !courseAvailability.some(
              (a) =>
                moment(a.date).format('YYYY-MM-DD') ===
                  moment(date).format('YYYY-MM-DD') && a.vacancies > 0
            ) || moment(date).isBefore(moment().startOf('day'))
          );
        }}
        onActiveStartDateChange={({ activeStartDate }) =>
          activeStartDate && setActiveStartDate(activeStartDate)
        }
      />
      {/* actions */}
      <div className="flex justify-end">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          disabled={!date}
        >
          Next
        </button>
      </div>
    </form>
  );
};
