import classNames from 'classnames';
import { ReactNode } from 'react';

interface Props {
  title: string;
  children?: ReactNode;
  className?: string;
}

export const Heading = (props: Props) => {
  const { title, children, className } = props;

  return (
    <div
      className={classNames(
        'flex items-center justify-between h-16 mb-4',
        className
      )}
    >
      <h2 className="text-xl font-medium">{title}</h2>
      <span>{children}</span>
    </div>
  );
};
