import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';

/* components */
import { Modal } from '../../../../Common/Modal/Modal';
import { Drawer } from '../../../../Common/Drawer/Drawer';
import { LocationForm } from '../../../../Forms/LocationForm/LocationForm';
import { Card } from '../../../../Common/Card/Card';

/* icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';

/* configs */
import api from '../../../../../configs/api';

/* types */
import { Location as LocationType } from '../../../../../types/Location';
import { Address } from '../../../../../types/Address';

interface Props {
  location: LocationType;
  onChange: () => void;
}

export const Location = (props: Props) => {
  const [location, setLocation] = useState<LocationType>(props.location);
  const [address, setAddress] = useState<Address>();

  /* update */
  const updateLocation = (location: LocationType) => {
    api
      .put(`/locations/${props.location.id}`, location)
      .then((response) => {
        setLocation(response.data);
        toast.success('Location updated!');
      })
      .then(() => props.onChange());
  };

  /* delete */
  const deleteLocation = () => {
    api
      .put(`/locations/${location.id}`, { ...location, deleted: true })
      .then((response) => {
        setLocation(response.data);
        toast.success('Location deleted!');
      })
      .then(() => props.onChange());
  };

  /* restore */
  const restoreLocation = () => {
    api
      .put(`/locations/${location.id}`, { ...location, deleted: false })
      .then((response) => {
        setLocation(response.data);
        toast.success('Location restored!');
      });
  };

  /* get the locations address */
  useEffect(() => {
    if (location?.address_id)
      api.get(`/addresses/${location.address_id}`).then((response) => {
        setAddress(response.data);
      });
  }, [location]); // eslint-disable-line

  /* card actions */
  const Actions = () => {
    return (
      <div className="flex gap-2">
        {/* delete location */}
        {!location.deleted && (
          <Modal
            id={`delete-location-${location.id}`}
            title="Delete Location"
            trigger={
              <button className="btn btn-sm">
                <FontAwesomeIcon icon={faTrash} />
              </button>
            }
            action={deleteLocation}
          >
            <div className="flex flex-col gap-4">
              <p>Are you sure you want to delete this location?</p>
            </div>
          </Modal>
        )}
        {/* edit location */}
        {!location.deleted && (
          <Drawer
            id={`edit-location-${location.id}`}
            title="Edit Location"
            trigger={
              <label
                htmlFor={`edit-location-${location.id}`}
                className="drawer-button btn btn-sm"
              >
                <FontAwesomeIcon icon={faPencil} />
                Edit
              </label>
            }
          >
            <LocationForm
              location={location}
              handleSubmit={(location) => updateLocation(location)}
            />
          </Drawer>
        )}
        {/* restore location */}
        {location.deleted && (
          <Modal
            id={`restore-location-${location.id}`}
            title="Restore Location"
            trigger={<button className="btn btn-sm">Restore</button>}
            action={restoreLocation}
          >
            Are you sure you want to restore this location?
          </Modal>
        )}
      </div>
    );
  };

  return (
    <Card
      image="placeholder"
      title={location.name}
      actions={<Actions />}
      className={classNames(location.deleted && 'bg-neutral-100')}
    >
      {/* location details */}
      <div className="text-sm">
        <p>{location.phone}</p>
        <p>{location.email}</p>
        <br />
        <p>{address?.address_line_1}</p>
        <p>{address?.address_line_2}</p>
        <p>{address?.city}</p>
        <p>{address?.postcode}</p>
      </div>
    </Card>
  );
};
