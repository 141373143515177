import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createColumnHelper } from '@tanstack/react-table';
import { toast } from 'react-toastify';
import moment from 'moment';
import classNames from 'classnames';

/* components */
import { Heading } from '../../../Common//Heading/Heading';
import { Table } from '../../../Common/Table/Table';
import { BookingForm } from '../../../Forms/BookingForm/BookingForm';
import { TableCheckbox } from '../../../Common/Table/TableCheckbox/TableCheckbox';
import { Modal } from '../../../Common/Modal/Modal';

/* configs */
import api from '../../../../configs/api';

/* redux */
import { startLoading, stopLoading } from '../../../../redux/loading';

/* types */
import { Booking, BookingDetails } from '../../../../types/Booking';

export const Bookings = () => {
  const dispatch = useDispatch();

  const [bookings, setBookings] = useState<BookingDetails[]>([]);

  /* get bookings */
  const getBookings = () => {
    dispatch(startLoading());

    api
      .get(`/bookings`)
      .then((response) => setBookings(response.data))
      .then(() => dispatch(stopLoading()));
  };

  /* update booking */
  const updateBooking = (booking: Booking) => {
    api
      .put(`/bookings/${booking.id}`, booking)
      .then(() => {
        toast.success('Booking updated!');
        getBookings();
      })
      .catch(() => toast.error('Error updating booking.'));
  };

  /* delete booking */
  const deleteBooking = (booking: BookingDetails) => {
    api
      .put(`/bookings/${booking.id}`, { ...booking, deleted: true })
      .then(() => {
        toast.success('Booking deleted!');
        getBookings();
      })
      .catch(() => toast.error('Error deleting booking.'));
  };

  useEffect(() => {
    getBookings();
  }, []); // eslint-disable-line

  const columnHelper = createColumnHelper<BookingDetails>();

  /* table columns */
  const columns = [
    columnHelper.display({
      id: 'select',
      header: ({ table }) => (
        <TableCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler()
          }}
        />
      ),
      cell: ({ row }) => (
        <TableCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler()
          }}
        />
      )
    }),
    columnHelper.accessor('date', {
      header: () => <span>Date</span>,
      cell: (info) => moment(info.getValue()).format('DD/MM/YYYY')
    }),
    columnHelper.accessor('customer_name', {
      header: () => <span>Customer</span>,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor(
      (row) => (
        <span className={classNames(row.course_deleted && 'text-error')}>
          {row.course_name}
        </span>
      ),
      {
        id: 'course_name',
        header: () => <span>Course</span>,
        cell: (info) => info.getValue()
      }
    ),
    columnHelper.accessor(
      (row) => (
        <span className={classNames(row.location_deleted && 'text-error')}>
          {row.location_name}
        </span>
      ),
      {
        id: 'location_name',
        header: () => <span>Location</span>,
        cell: (info) => info.getValue()
      }
    ),
    columnHelper.accessor(
      (row) => (
        <span className={classNames(row.vehicle_deleted && 'text-error')}>
          {row.vehicle_name}
        </span>
      ),
      {
        id: 'vehicle_name',
        header: () => <span>Vehicle</span>,
        cell: (info) => info.getValue()
      }
    ),
    columnHelper.display({
      id: 'delete',
      header: () => <span>Delete</span>,
      cell: ({ row }) => (
        <Modal
          id={`delete-license-${row.original.id}`}
          title="Delete Booking"
          trigger={<button className="btn btn-sm">Delete</button>}
          action={() => deleteBooking(row.original)}
        >
          Are you sure you want to delete this booking?
        </Modal>
      )
    })
  ];

  return (
    <>
      <Heading title="Bookings" />
      {/* bookings */}
      <Table
        columns={columns}
        data={bookings.filter(
          (booking) => !booking.deleted && !booking.cancelled
        )}
        drawer={{
          id: 'edit-booking',
          name: 'Edit Booking',
          content: (
            <BookingForm handleSubmit={(booking) => updateBooking(booking)} />
          )
        }}
        enableRowSelection
        fixedPagination
      />
    </>
  );
};
