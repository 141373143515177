import { BaseSyntheticEvent, cloneElement } from 'react';
import { Row, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';

/* components */
import { Drawer } from '../../Drawer/Drawer';

/* types */
import { DrawerConfig } from '../../../../types/Drawer';

interface Props {
  row: Row<any>; // eslint-disable-line
  drawer?: DrawerConfig;
}

export const TableRow = (props: Props) => {
  const { row, drawer } = props;

  const drawerContent =
    drawer && cloneElement(drawer.content, { data: row.original });

  return (
    <>
      {drawer && (
        <Drawer
          id={`${drawer.id}-${row.original.id}`}
          title={drawer.name || 'Content'}
        >
          {drawerContent}
        </Drawer>
      )}
      <tr
        key={row.id}
        className={classNames(drawer && 'cursor-pointer hover:bg-neutral-50')}
        onClick={(event: BaseSyntheticEvent) => {
          /* do not open drawer if the user is interacting with an input, link, or button */
          if (
            event.target instanceof HTMLInputElement ||
            event.target instanceof HTMLAnchorElement ||
            event.target instanceof HTMLButtonElement
          )
            return;

          /* open the drawer */
          return (
            drawer &&
            document.getElementById(`${drawer.id}-${row.original.id}`)?.click()
          );
        }}
      >
        {row.getVisibleCells().map((cell) => (
          <td key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>
    </>
  );
};
