import { useDispatch } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';

/* components */
import { Heading } from '../../../../Common/Heading/Heading';

/* redux */
import { startLoading, stopLoading } from '../../../../../redux/loading';

/* configs */
import api from '../../../../../configs/api';

/* types */
import { Booking } from '../../../../../types/Booking';
import { Course } from '../../../../../types/Course';
import { Customer } from '../../../../../types/Customer';
import { Location } from '../../../../../types/Location';

interface Props {
  customer: Customer;
  course: Course;
  location: Location;
  date: Date;
  handleSubmit: (data: Booking) => void;
}

export const CreateBooking = (props: Props) => {
  const { customer, course, location } = props;

  const dispatch = useDispatch();

  /* create the booking */
  const createBooking = () => {
    if (!customer || !course || !location || !props.date)
      return toast.error('All fields are required.');

    dispatch(startLoading());

    const date = moment(props.date).format('YYYY-MM-DD');

    const booking: Booking = {
      customer_id: customer.id,
      course_id: course.id,
      location_id: location.id,
      date
    };

    /* find an available vehicle */
    api
      .get(`/availability/courses/${course.id}/date/${date}/vehicles`)
      .then((response) => {
        if (response.data.length === 0) {
          toast.error('No vehicles available for this course.');
          dispatch(stopLoading());
          return;
        }

        booking.vehicle_id = response.data[0].vehicle_id;

        /* create the booking */
        api
          .post('/bookings', booking)
          .then((response) => {
            props.handleSubmit(response.data);
            dispatch(stopLoading());
          })
          .catch((error) => {
            dispatch(stopLoading());
            toast.error('Failed to create booking.');
          });
      });
  };

  return (
    <>
      <Heading title="Confirm Booking" />
      <div className="flex flex-col gap-4">
        <div>
          <div>Name: {`${customer.first_name} ${customer.last_name}`}</div>
          <div>Email: {customer.email}</div>
          <div>Phone: {customer.phone}</div>
        </div>
        <div>Location: {location.name}</div>
        <div>Course: {course.name}</div>
        <div>Date: {moment(props.date).format('Do MMMM YYYY')}</div>
        <button className="btn btn-sm btn-primary" onClick={createBooking}>
          Confirm
        </button>
      </div>
    </>
  );
};
