import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

/* components */
import { Location } from './Location/Location';
import { LocationForm } from '../../../Forms/LocationForm/LocationForm';
import { Drawer } from '../../../Common/Drawer/Drawer';
import { Heading } from '../../../Common/Heading/Heading';

/* configs */
import api from '../../../../configs/api';

/* redux */
import { startLoading, stopLoading } from '../../../../redux/loading';

/* types */
import { Location as LocationType } from '../../../../types/Location';

export const Locations = () => {
  const dispatch = useDispatch();

  const [locations, setLocations] = useState<LocationType[]>([]);

  const getLocations = () => {
    api
      .get(`/locations`)
      .then((response) => setLocations(response.data))
      .then(() => dispatch(stopLoading()));
  };

  const createLocation = (location: LocationType) => {
    api.post(`/locations`, location).then(() => {
      toast.success('Location created!');
      getLocations();
    });
  };

  useEffect(() => {
    dispatch(startLoading());
    getLocations();
  }, []); // eslint-disable-line

  return (
    <>
      <Heading title="Locations">
        {/* new location form */}
        <Drawer
          id="new-location"
          title="New Location"
          trigger={
            <label
              htmlFor="new-location"
              className="drawer-button btn btn-primary btn-sm"
            >
              New
            </label>
          }
        >
          <LocationForm handleSubmit={(location) => createLocation(location)} />
        </Drawer>
      </Heading>
      {/* locations */}
      <div
        className="grid grid-cols-1 xs:grid-cols-2 
                  lg:grid-cols-3 xl:grid-cols-4 gap-4"
      >
        {locations.map((location) => (
          <Location
            location={location}
            onChange={getLocations}
            key={location.id}
          />
        ))}
      </div>
    </>
  );
};
