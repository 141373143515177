import { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

/* configs */
import api from '../../../configs/api';

/* types */
import { Course } from '../../../types/Course';

interface Props {
  date: Date;
  onUpdate: () => void;
}

export const CourseSelectionForm = (props: Props) => {
  const { date } = props;

  const [courses, setCourses] = useState<Course[]>([]);

  const addCourse = (course: Course) => {
    api
      .post(
        `/calendar/${moment(date).format('YYYY-MM-DD')}/courses/${course.id}`
      )
      .then(() => {
        props.onUpdate();
        toast.success('Course added to the calendar');
        document.getElementById('new-calendar-course')?.click();
      })
      .catch((error) => console.error(toast.error(error.message)));
  };

  /* get all available courses */
  useEffect(() => {
    api.get(`/courses`).then((response) => setCourses(response.data));
  }, []);

  return (
    <div className="flex flex-col gap-4 mt-4">
      {courses.map((course) => (
        <div
          key={course.id}
          onClick={() => addCourse(course)}
          className="cursor-pointer border border-neutral-200 rounded p-2
                    hover:bg-neutral-200 transition-all flex flex-col gap-1"
          tabIndex={1}
        >
          <div className="font-bold">{course.name}</div>
          <div>{course.description}</div>
          <div>Capacity: {course.capacity}</div>
          <div>Price: £{course.price}</div>
        </div>
      ))}
    </div>
  );
};
