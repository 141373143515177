import { FormEvent, useEffect, useState } from 'react';

/* components */
import { Input } from '../../Common/Input/Input';
import { Select } from '../../Common/Select/Select';

/* icons */
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* configs */
import api from '../../../configs/api';

/* types */
import { License } from '../../../types/License';
import { Course } from '../../../types/Course';

/* props */
interface Props {
  course?: Course;
  handleSubmit: (data: Course) => void;
}

export const CourseForm = (props: Props) => {
  const { course } = props;

  const [licenses, setLicenses] = useState<License[]>([]);
  const [licenseRequired, setLicenseRequired] = useState<string>();
  const [licenseObtained, setLicenseObtained] = useState<string>();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const data = Object.fromEntries(
      new FormData(form).entries()
    ) as unknown as Course;

    props.handleSubmit(data);
  };

  /* get the licenses */
  useEffect(() => {
    api.get(`/licenses`).then((response) => setLicenses(response.data));
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
      method="post"
      className="flex flex-col gap-4 mt-8"
    >
      {/* name */}
      <Input name="name" type="text" label="Name" defaultValue={course?.name} />
      {/* description */}
      <Input
        name="description"
        type="text"
        label="Description"
        defaultValue={course?.description}
      />
      {/* additional information */}
      <Input
        name="additional_information"
        type="text"
        label="Additional Information"
        defaultValue={course?.additional_information}
      />
      {/* license required */}
      <div className="flex justify-between items-baseline">
        <Select
          name="license_required"
          label="License Required"
          value={licenseRequired || course?.license_required}
          options={licenses.map((license) => {
            return {
              label: license.name,
              value: license.id
            };
          })}
          onChange={(e) => setLicenseRequired(e.target.value)}
        />
        {/* error badge */}
        {licenses.find((license) => license.id === course?.license_required)
          ?.deleted && (
          <span className="badge badge-sm badge-error mx-2">
            <FontAwesomeIcon
              icon={faExclamation}
              className="text-neutral-000"
            />
          </span>
        )}
      </div>
      {/* license obtained */}
      <div className="flex justify-between items-baseline">
        <Select
          name="license_obtained"
          label="License Obtained"
          value={licenseObtained || course?.license_obtained}
          options={licenses.map((license) => {
            return {
              label: license.name,
              value: license.id
            };
          })}
          onChange={(e) => setLicenseObtained(e.target.value)}
        />
        {/* error badge */}
        {licenses.find((license) => license.id === course?.license_obtained)
          ?.deleted && (
          <span className="badge badge-sm badge-error mx-2">
            <FontAwesomeIcon
              icon={faExclamation}
              className="text-neutral-000"
            />
          </span>
        )}
      </div>
      {/* price */}
      <Input
        name="price"
        type="number"
        label="Price"
        step="0.1"
        defaultValue={course?.price}
      />
      {/* days */}
      <Input
        name="days"
        type="number"
        label="Days"
        defaultValue={course?.days}
      />
      {/* capacity */}
      <Input
        name="capacity"
        type="number"
        label="Capacity"
        defaultValue={course?.capacity}
      />
      <div className="flex justify-end mt-4">
        <button type="submit" className="btn btn-sm btn-primary">
          Submit form
        </button>
      </div>
    </form>
  );
};
