import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';

/* components */
import { LicenseForm } from '../../../../Forms/LicenseForm/LicenseForm';
import { Drawer } from '../../../../Common/Drawer/Drawer';
import { Modal } from '../../../../Common/Modal/Modal';
import { Card } from '../../../../Common/Card/Card';
import { LicenseRequirementForm } from '../../../../Forms/LicenseRequirementForm/LicenseRequirementForm';
import { LicenseRequirement } from './LicenseRequirement/LicenseRequirement';

/* configs */
import api from '../../../../../configs/api';

/* icons */
import { faAdd, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* types */
import { License as LicenseType } from '../../../../../types/License';
import { LicenseRequirement as LicenseRequirementType } from '../../../../../types/LicenseRequirement';

interface Props {
  license: LicenseType;
  onChange: () => void;
}

export const License = (props: Props) => {
  const [license, setLicense] = useState<LicenseType>(props.license);
  const [requirements, setRequirements] = useState<LicenseRequirementType[]>();

  /* update */
  const updateLicense = (license: LicenseType) => {
    api
      .put(`/licenses/${props.license.id}`, license)
      .then((response) => {
        setLicense(response.data);
        toast.success('License updated!');
      })
      .then(() => props.onChange());
  };

  /* delete */
  const deleteLicense = () => {
    api
      .put(`/licenses/${license.id}`, { ...license, deleted: true })
      .then((response) => {
        setLicense(response.data);
        toast.success('License deleted!');
      })
      .then(() => props.onChange());
  };

  /* restore */
  const restoreLicense = () => {
    api
      .put(`/licenses/${license.id}`, { ...license, deleted: false })
      .then((response) => {
        setLicense(response.data);
        toast.success('License restored!');
      });
  };

  /* get requirements */
  const getRequirements = () => {
    api
      .get(`/licenses/${license.id}/requirements`)
      .then((response) => setRequirements(response.data));
  };

  /* get the license requirements */
  useEffect(() => {
    getRequirements();
  }, []); // eslint-disable-line

  /* card actions */
  const Actions = () => {
    return (
      <div className="flex gap-2">
        {/* delete license */}
        {!license.deleted && (
          <Modal
            id={`delete-license-${license.id}`}
            title="Delete License"
            trigger={
              <button className="btn btn-sm">
                <FontAwesomeIcon icon={faTrash} />
              </button>
            }
            action={deleteLicense}
          >
            Are you sure you want to delete this license?
          </Modal>
        )}
        {/* edit license */}
        {!license.deleted && (
          <Drawer
            id={`edit-license-${license.id}`}
            title="Edit License"
            trigger={
              <label
                htmlFor={`edit-license-${license.id}`}
                className="drawer-button btn btn-sm"
              >
                <span className="flex gap-2">
                  <FontAwesomeIcon icon={faPencil} />
                  Edit
                </span>
              </label>
            }
          >
            <LicenseForm
              license={license}
              handleSubmit={(license) => updateLicense(license)}
            />
          </Drawer>
        )}
        {/* add requirement */}
        {!license.deleted && (
          <Drawer
            id={`add-requirement-${license.id}`}
            title={`${license.name} - Add Requirement`}
            trigger={
              <label
                htmlFor={`add-requirement-${license.id}`}
                className="drawer-button btn btn-sm"
              >
                <span className="flex gap-2">
                  <FontAwesomeIcon icon={faAdd} />
                  Req
                </span>
              </label>
            }
          >
            <LicenseRequirementForm
              license={license}
              handleSubmit={() => getRequirements()}
            />
          </Drawer>
        )}
        {/* restore license */}
        {license.deleted && (
          <Modal
            id={`restore-license-${license.id}`}
            title="Restore License"
            trigger={<button className="btn btn-sm">Restore</button>}
            action={restoreLicense}
          >
            Are you sure you want to restore this license?
          </Modal>
        )}
      </div>
    );
  };

  return (
    <Card
      image="placeholder"
      title={license.name}
      actions={<Actions />}
      className={classNames(license.deleted && 'bg-neutral-100')}
    >
      <div className="text-sm">{license.description}</div>
      {/* requirements */}
      {requirements && requirements.length > 0 && (
        <>
          <div className="font-bold mt-4">Requirements</div>
          <div className="flex flex-col gap-2">
            {requirements.map((requirement) => {
              return (
                <LicenseRequirement
                  requirement={requirement}
                  key={requirement.id}
                  onDelete={() => getRequirements()}
                />
              );
            })}
          </div>
        </>
      )}
      <div className="flex-1"></div>
    </Card>
  );
};
