import axios from 'axios';
import { instance } from './auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(async (config) => {
  const request = {
    scopes: ['api://022024a2-b3be-4fe7-bb1f-9fcc431e039c/user'],
    account: instance.getAllAccounts()[0]
  };

  const token = await instance
    .acquireTokenSilent(request)
    .then((response) => response.accessToken)
    .catch(() =>
      instance
        .acquireTokenPopup(request)
        .then((response) => response.accessToken)
    );

  const bearer = `Bearer ${token}`;

  config.headers.Authorization = bearer;

  return config;
});

export default api;
