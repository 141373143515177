import { toast } from 'react-toastify';
import moment from 'moment';

/* configs */
import api from '../../../../../configs/api';

/* icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faTrash } from '@fortawesome/free-solid-svg-icons';

/* types */
import { CourseDate } from '../../../../../types/Course';

interface Props {
  course: CourseDate;
  onUpdate: () => void;
}

export const CalendarCourse = (props: Props) => {
  const { course } = props;

  const removeCourse = (course: CourseDate) => {
    api
      .delete(
        `/calendar/${moment(course.date).format('YYYY-MM-DD')}/courses/${
          course.id
        }`
      )
      .then(() => {
        props.onUpdate();
        toast.success('Course removed!');
      });
  };

  return (
    <div
      className="border border-neutral-200 rounded p-2 flex 
                justify-between items-center"
    >
      {/* course details */}
      <div className="flex flex-col gap-1">
        <div className="font-bold">{course.name}</div>
        <div>{course.description}</div>
        <div>Capacity: {course.capacity}</div>
      </div>
      <div className="flex items-center gap-2">
        {/* error badge */}
        {course.deleted && (
          <span className="badge badge-sm badge-error mx-2">
            <FontAwesomeIcon
              icon={faExclamation}
              className="text-neutral-000"
            />
          </span>
        )}
        {/* remove course */}
        <button
          onClick={() => removeCourse(course)}
          className="btn btn-sm btn-danger"
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </div>
  );
};
