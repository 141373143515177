import { FormEvent, useEffect, useState } from 'react';
import moment from 'moment';

/* components */
import { Input } from '../../Common/Input/Input';
import { Select } from '../../Common/Select/Select';

/* configs */
import api from '../../../configs/api';

/* types */
import { Customer } from '../../../types/Customer';
import { Experience } from '../../../types/Experience';
import { License } from '../../../types/License';
import { Checkbox } from '../../Common/Checkbox/Checkbox';

/* props */
interface Props {
  data?: Customer;
  handleSubmit: (data: Customer) => void;
}

export const CustomerForm = (props: Props) => {
  const customer = props.data;

  const [experience, setExperience] = useState<string>();
  const [experiences, setExperiences] = useState<Experience[]>([]);

  const [license, setLicense] = useState<string>();
  const [licenses, setLicenses] = useState<License[]>([]);

  const [theory, setTheory] = useState<boolean>(customer?.has_theory || false);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const data = Object.fromEntries(
      new FormData(form).entries()
    ) as unknown as Customer;

    data.has_theory = theory;

    props.handleSubmit({ ...customer, ...data });
  };

  useEffect(() => {
    /* get experiences */
    api.get(`/experiences`).then((response) => setExperiences(response.data));

    /* get licenses */
    api.get(`/licenses`).then((response) => setLicenses(response.data));
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
      method="post"
      className="flex flex-col gap-4 mt-8"
    >
      {/* first name */}
      <Input
        name="first_name"
        type="text"
        label="First Name"
        defaultValue={customer?.first_name}
      />
      {/* Last Name */}
      <Input
        name="last_name"
        type="text"
        label="Last Name"
        defaultValue={customer?.last_name}
      />
      {/* email */}
      <Input
        name="email"
        type="text"
        label="Email"
        defaultValue={customer?.email}
      />
      {/* phone */}
      <Input
        name="phone"
        type="phone"
        label="Phone"
        defaultValue={customer?.phone}
      />
      {/* date of birth */}
      <Input
        name="date_of_birth"
        type="date"
        label="Date of Birth"
        placeholder={
          moment(customer?.date_of_birth).format('YYYY-MM-DD') || 'dd/mm/yyyy'
        }
        defaultValue={moment(customer?.date_of_birth).format('YYYY-MM-DD')}
      />
      {/* experience */}
      <Select
        name="experience_id"
        label="Experience"
        value={experience || customer?.experience_id}
        options={experiences.map((experience) => {
          return {
            label: experience.name,
            value: experience.id
          };
        })}
        onChange={(e) => setExperience(e.target.value)}
      />
      {/* license */}
      <Select
        name="license_id"
        label="License"
        value={license || customer?.license_id}
        options={licenses.map((license) => {
          return {
            label: license.name,
            value: license.id
          };
        })}
        onChange={(e) => setLicense(e.target.value)}
      />
      {/* date license obtained */}
      <Input
        name="date_license_obtained"
        type="date"
        label="Date License Obtained"
        placeholder={
          moment(customer?.date_license_obtained).format('YYYY-MM-DD') ||
          'dd/mm/yyyy'
        }
        defaultValue={moment(customer?.date_license_obtained).format(
          'YYYY-MM-DD'
        )}
      />
      {/* has theory */}
      <div className="flex gap-4 mt-4">
        <Checkbox
          name="has_theory"
          onChange={() => setTheory(!theory)}
          checked={theory}
        />
        <label htmlFor="has_theory" className="text-sm">
          Has Theory
        </label>
      </div>
      {/* date theory passed */}
      {theory && (
        <Input
          name="date_theory_passed"
          type="date"
          label="Date Theory Passed"
          placeholder={
            moment(customer?.date_theory_passed).format('YYYY-MM-DD') ||
            'dd/mm/yyyy'
          }
          defaultValue={moment(customer?.date_theory_passed).format(
            'YYYY-MM-DD'
          )}
        />
      )}
      {/* submit */}
      <div className="flex justify-end mt-4">
        <button type="submit" className="btn btn-sm btn-primary">
          Submit form
        </button>
      </div>
    </form>
  );
};
