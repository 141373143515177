import { useEffect, useState } from 'react';

/* components */
import { Modal } from '../../../../../Common/Modal/Modal';

/* configs */
import api from '../../../../../../configs/api';

/* icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

/* types */
import { LicenseRequirement as LicenseRequirementType } from '../../../../../../types/LicenseRequirement';
import { License } from '../../../../../../types/License';

interface Props {
  requirement: LicenseRequirementType;
  onDelete: () => void;
}

export const LicenseRequirement = (props: Props) => {
  const { requirement } = props;

  const [licenseRequired, setLicenseRequired] = useState<License>();

  /* delete requirement */
  const deleteRequirement = () => {
    api
      .delete(
        `/licenses/${requirement.license_id}/requirements/${requirement.id}`
      )
      .then(() => props.onDelete());
  };

  /* get license required */
  useEffect(() => {
    api
      .get(`/licenses/${requirement.license_required_id}`)
      .then((response) => setLicenseRequired(response.data));
  }, [requirement.license_required_id]);

  return (
    <div className="flex justify-between items-center border border-neutral-200 p-2 rounded">
      <div>
        <p>License required: {licenseRequired?.name}</p>
        <p>Years license held: {requirement.years_license_held}</p>
        <p>Age: {requirement.age}</p>
        <p>Theory required: {requirement.theory_required ? 'Yes' : 'No'}</p>
      </div>
      {/* delete requirement */}
      <Modal
        id={`delete-license-requirement-${requirement.id}`}
        title="Delete License Requirement"
        trigger={
          <button className="btn btn-sm">
            <FontAwesomeIcon icon={faTrash} />
          </button>
        }
        action={deleteRequirement}
      >
        Are you sure you want to delete this license requirement?
      </Modal>
    </div>
  );
};
