import { ChangeEvent } from 'react';

/* css */
import './Checkbox.scss';

interface Props {
  name: string;
  checked: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => unknown;
}

export const Checkbox = (props: Props) => {
  const { name, checked } = props;

  return (
    <label className="checkbox-container">
      <input
        name={name}
        type="checkbox"
        checked={checked}
        onChange={props.onChange}
      />
      <span className="checkmark transition"></span>
    </label>
  );
};
