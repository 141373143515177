import { useEffect, useState } from 'react';
import classNames from 'classnames';

/* components */
import { Modal } from '../../../../Common/Modal/Modal';
import { Drawer } from '../../../../Common/Drawer/Drawer';
import { VehicleForm } from '../../../../Forms/VehicleForm/VehicleForm';
import { Card } from '../../../../Common/Card/Card';

/* icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';

/* api */
import api from '../../../../../configs/api';
import {
  deleteVehicle,
  restoreVehicle,
  updateVehicle
} from '../../../../../api/vehicles';

/* types */
import { IVehicle } from '../../../../../types/Vehicle';

interface Props {
  vehicle: IVehicle;
}

export const Vehicle = (props: Props) => {
  const [vehicle, setVehicle] = useState<IVehicle>(props.vehicle);
  const [vehicleType, setVehicleType] = useState<string>();

  /* get the vehicles type */
  useEffect(() => {
    api.get(`/vehicle_types/${props.vehicle.type}`).then((response) => {
      setVehicleType(response.data.name);
    });
  }, [props.vehicle]);

  /* card actions */
  const Actions = () => {
    return (
      <div className="flex gap-2">
        {/* delete vehicle */}
        {!vehicle.deleted && (
          <Modal
            id={`delete-vehicle-${vehicle.id}`}
            title="Delete Vehicle"
            trigger={
              <button className="btn btn-sm">
                <FontAwesomeIcon icon={faTrash} />
              </button>
            }
            action={() =>
              deleteVehicle(vehicle).then((vehicle) => setVehicle(vehicle))
            }
          >
            <div className="flex flex-col gap-4">
              <p>Are you sure you want to delete this vehicle?</p>
            </div>
          </Modal>
        )}
        {/* edit vehicle */}
        {!vehicle.deleted && (
          <Drawer
            id={`edit-vehicle-${vehicle.id}`}
            title="Edit Vehicle"
            trigger={
              <label
                htmlFor={`edit-vehicle-${vehicle.id}`}
                className="drawer-button btn btn-sm"
              >
                <FontAwesomeIcon icon={faPencil} />
                Edit
              </label>
            }
          >
            <VehicleForm
              vehicle={vehicle}
              handleSubmit={(vehicle) =>
                updateVehicle(vehicle).then((vehicle) => {
                  if (vehicle) setVehicle(vehicle);
                })
              }
            />
          </Drawer>
        )}
        {/* restore vehicle */}
        {vehicle.deleted && (
          <Modal
            id={`restore-vehicle-${vehicle.id}`}
            title="Restore Vehicle"
            trigger={<button className="btn btn-sm">Restore</button>}
            action={() =>
              restoreVehicle(vehicle).then((vehicle) => setVehicle(vehicle))
            }
          >
            Are you sure you want to restore this vehicle?
          </Modal>
        )}
      </div>
    );
  };

  return (
    <Card
      image="placeholder"
      title={`${vehicle.make} ${vehicle.model}`}
      actions={<Actions />}
      className={classNames(vehicle.deleted && 'bg-neutral-100')}
    >
      {/* vehicle details */}
      <div className="text-sm">
        <div>{vehicleType || ''}</div>
        <div>{vehicle.year}</div>
        <div>{vehicle.color}</div>
        <div>{vehicle.registration}</div>
        <div>{vehicle.displacement}cc</div>
      </div>
    </Card>
  );
};
