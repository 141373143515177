import { configureStore } from '@reduxjs/toolkit';

/* reducers */
import loadingReducer from '../redux/loading';

export default configureStore({
  reducer: {
    loading: loadingReducer
  }
});
