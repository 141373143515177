import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

/* icons */
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  to: string;
  icon: IconProp;
  children: ReactNode;
  badge: number;
}

export const Link = (props: Props) => {
  const { to, icon, children, badge } = props;

  return (
    <li>
      <NavLink
        to={to}
        className={({ isActive }) =>
          (isActive && 'bg-neutral-100') + ' transition duration-300'
        }
      >
        <FontAwesomeIcon icon={icon} className="w-5" />
        {children}
        {badge > 0 && <span className="badge badge-sm">{badge}</span>}
      </NavLink>
    </li>
  );
};
