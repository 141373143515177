/* components */
import { Placeholder } from '../../../../../Common/Placeholder/Placeholder';
import { Modal } from '../../../../../Common/Modal/Modal';

/* configs */
import api from '../../../../../../configs/api';

/* icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faTrash } from '@fortawesome/free-solid-svg-icons';

/* types */
import { IVehicle } from '../../../../../../types/Vehicle';

interface Props {
  vehicle: IVehicle;
  course_id: string;
  onDelete: () => void;
}

export const Vehicle = (props: Props) => {
  const { vehicle, course_id } = props;

  /* remove vehicle from course */
  const removeVehicle = () => {
    api
      .delete(`/courses/${course_id}/vehicles/${vehicle.id}`)
      .then(() => props.onDelete());
  };

  return vehicle ? (
    <div className="flex justify-between items-center border border-neutral-200 p-2 rounded">
      <div className="flex items-center gap-4">
        {/* image */}
        <div className="w-10 h-10 rounded flex items-center overflow-hidden">
          <Placeholder />
        </div>
        {/* make, model and registration */}
        <div className="flex flex-col">
          <div className="text-xs font-medium">{`${vehicle.make} ${vehicle.model} (${vehicle.displacement}cc)`}</div>
          <div className="text-xs">{vehicle.registration}</div>
        </div>
      </div>
      {/* error badge */}
      {vehicle.deleted && (
        <span className="badge badge-sm badge-error mx-2">
          <FontAwesomeIcon icon={faExclamation} className="text-neutral-000" />
        </span>
      )}
      {/* remove vehicle from course */}
      <Modal
        id={`delete-course-vehicle-${vehicle.id}`}
        title="Remove Vehicle"
        trigger={
          <button className="btn btn-sm">
            <FontAwesomeIcon icon={faTrash} />
          </button>
        }
        action={removeVehicle}
      >
        Are you sure you want to remove this vehicle from this course?
      </Modal>
    </div>
  ) : (
    <></>
  );
};
