import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

/* configs */
import graph from '../../../../configs/graph';

/* redux */
import { startLoading, stopLoading } from '../../../../redux/loading';

/* types */
interface GraphData {
  givenName: string;
  surname: string;
  userPrincipalName: string;
  id: string;
}

export const Profile = () => {
  const dispatch = useDispatch();

  const [graphData, setGraphData] = useState<GraphData>();

  useEffect(() => {
    dispatch(startLoading());

    graph
      .get('/me')
      .then((response) => setGraphData(response.data))
      .then(() => dispatch(stopLoading()))
      .catch((error) => console.log(error));
  }, []); // eslint-disable-line

  return (
    <>
      {graphData && (
        <div id="profile-div">
          <p>
            <strong>First Name: </strong> {graphData.givenName}
          </p>
          <p>
            <strong>Last Name: </strong> {graphData.surname}
          </p>
          <p>
            <strong>Email: </strong> {graphData.userPrincipalName}
          </p>
          <p>
            <strong>Id: </strong> {graphData.id}
          </p>
        </div>
      )}
    </>
  );
};
