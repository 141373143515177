import { useState } from 'react';
import classNames from 'classnames';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  PaginationState,
  RowSelectionState,
  ColumnDef
} from '@tanstack/react-table';

/* components */
import { TableCheckbox } from './TableCheckbox/TableCheckbox';
import { TableHeaderRow } from './TableHeaderRow/TableHeaderRow';
import { TableRow } from './TableRow/TableRow';

/* hooks */
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

/* types */
import { DrawerConfig } from '../../../types/Drawer';

interface Props {
  columns: ColumnDef<any, any>[]; // eslint-disable-line
  data: any[]; // eslint-disable-line
  enableRowSelection?: boolean;
  drawer?: DrawerConfig;
  fixedPagination?: boolean;
}

export const Table = (props: Props) => {
  const { columns, data, drawer, fixedPagination } = props;

  const { width } = useWindowDimensions();

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const table = useReactTable({
    columns,
    data,
    state: {
      sorting,
      pagination,
      rowSelection
    },
    enableRowSelection: props.enableRowSelection ? true : false,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  return (
    <div>
      {/* rows selected */}
      {props.enableRowSelection && (
        <div className="text-sm mb-4">
          {Object.keys(rowSelection).length} of{' '}
          {table.getPreFilteredRowModel().rows.length} Total Rows Selected
        </div>
      )}
      <table className="table bg-neutral-000">
        {/* table head */}
        <thead className="h-16">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableHeaderRow key={headerGroup.id} {...{ headerGroup }} />
          ))}
        </thead>
        {/* table body */}
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} {...{ row }} drawer={drawer} />
          ))}
        </tbody>
        {/* table footer */}
        {props.enableRowSelection && (
          <tfoot>
            <tr>
              <td className="p-4">
                <TableCheckbox
                  {...{
                    checked: table.getIsAllPageRowsSelected(),
                    indeterminate: table.getIsSomePageRowsSelected(),
                    onChange: table.getToggleAllPageRowsSelectedHandler()
                  }}
                />
              </td>
              <td colSpan={20}>
                Page Rows ({table.getRowModel().rows.length})
              </td>
            </tr>
          </tfoot>
        )}
      </table>
      <div className="h-2" />
      {/* pagination */}
      <div
        className={classNames(
          'flex h-16 items-center p-4 justify-between bg-neutral-000 text-sm',
          fixedPagination &&
            'fixed bottom-0 left-0 border-t border-neutral-100 ' +
              (width <= 768 ? 'w-full' : 'w-[calc(100%-12rem)] left-[12rem]')
        )}
      >
        {/* page number */}
        <div className="flex gap-2 items-center">
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount().toLocaleString()}
            </strong>
          </span>
        </div>
        {/* page navigation */}
        <div className="flex gap-2">
          <button
            className="btn btn-sm"
            onClick={() => table.firstPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className="btn btn-sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className="btn btn-sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className="btn btn-sm"
            onClick={() => table.lastPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
        </div>
        {/* page size */}
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
          className="select select-sm"
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
