import { FormEvent, useEffect, useState } from 'react';
import moment from 'moment';

/* components */
import { Select } from '../../Common/Select/Select';
import { Input } from '../../Common/Input/Input';

/* configs */
import api from '../../../configs/api';

/* icons */
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* types */
import { Booking } from '../../../types/Booking';
import { Course } from '../../../types/Course';
import { Location } from '../../../types/Location';
import { Customer } from '../../../types/Customer';
import { IVehicle } from '../../../types/Vehicle';

interface Props {
  data?: Booking;
  handleSubmit: (data: Booking) => void;
}

export const BookingForm = (props: Props) => {
  const booking = props.data;

  const [date, setDate] = useState<string | undefined>(
    moment(booking?.date).format('YYYY-MM-DD')
  );

  const [customer, setCustomer] = useState<Customer>();

  const [course, setCourse] = useState<string>(booking?.course_id || '');
  const [courses, setCourses] = useState<Course[]>([]);

  const [vehicle, setVehicle] = useState<string>(booking?.vehicle_id || '');
  const [vehicles, setVehicles] = useState<IVehicle[]>([]);

  const [location, setLocation] = useState<string>(booking?.location_id || '');
  const [locations, setLocations] = useState<Location[]>([]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const data = Object.fromEntries(
      new FormData(form).entries()
    ) as unknown as Booking;

    props.handleSubmit({ ...booking, ...data });
  };

  useEffect(() => {
    if (!date || !booking?.customer_id) return;

    const promises: Promise<void>[] = [];

    /* get the courses */
    promises.push(
      api
        .get(`calendar/${date}/courses`)
        .then((response) => setCourses(response.data))
    );

    /* get the locations */
    promises.push(
      api.get(`/locations`).then((response) => setLocations(response.data))
    );

    /* get the customer the booking is for */
    promises.push(
      api
        .get(`/customers/${booking?.customer_id}`)
        .then((response) => setCustomer(response.data))
    );

    Promise.all(promises);
  }, [date, booking?.customer_id]);

  useEffect(() => {
    if (!course) return;

    /* get the vehicles */
    api
      .get(`courses/${course}/vehicles`)
      .then((response) => setVehicles(response.data));
  }, [course]);

  return (
    <>
      {/* customer */}
      <div className="mt-8 flex flex-col gap-1">
        <div>{`${customer?.first_name} ${customer?.last_name}`}</div>
        <div>{customer?.email}</div>
        <div>{customer?.phone}</div>
      </div>

      <form
        onSubmit={handleSubmit}
        method="post"
        className="flex flex-col gap-4 mt-8"
      >
        {/* date */}
        <Input
          name="date"
          label="Date"
          type="date"
          placeholder={date || 'dd/mm/yyyy'}
          defaultValue={date}
          onChange={(e) => setDate(e.target.value)}
        />

        {/* course */}
        <div className="flex justify-between items-baseline">
          <Select
            name="course_id"
            label="Course"
            value={course || booking?.course_id}
            options={courses.map((course) => {
              return {
                label: course.name,
                value: course.id
              };
            })}
            onChange={(e) => setCourse(e.target.value)}
          />
          {/* error badge */}
          {courses.find((course) => course.id === booking?.course_id)
            ?.deleted && (
            <span className="badge badge-sm badge-error mx-2">
              <FontAwesomeIcon
                icon={faExclamation}
                className="text-neutral-000"
              />
            </span>
          )}
        </div>

        {/* vehicle */}
        <div className="flex justify-between items-baseline">
          <Select
            name="vehicle_id"
            label="Vehicle"
            value={vehicle || booking?.vehicle_id}
            options={vehicles.map((vehicle) => {
              return {
                label: `${vehicle.make} ${vehicle.model}`,
                value: vehicle.id
              };
            })}
            onChange={(e) => setVehicle(e.target.value)}
          />
          {/* error badge */}
          {vehicles.find((vehicle) => vehicle.id === booking?.vehicle_id)
            ?.deleted && (
            <span className="badge badge-sm badge-error mx-2">
              <FontAwesomeIcon
                icon={faExclamation}
                className="text-neutral-000"
              />
            </span>
          )}
        </div>

        {/* location */}
        <div className="flex justify-between items-baseline">
          <Select
            name="location_id"
            label="Location"
            value={location || booking?.location_id}
            options={locations.map((location) => {
              return {
                label: location.name,
                value: location.id
              };
            })}
            onChange={(e) => setLocation(e.target.value)}
          />
          {/* error badge */}
          {locations.find((location) => location.id === booking?.location_id)
            ?.deleted && (
            <span className="badge badge-sm badge-error mx-2">
              <FontAwesomeIcon
                icon={faExclamation}
                className="text-neutral-000"
              />
            </span>
          )}
        </div>

        <div className="flex justify-end mt-4">
          <button type="submit" className="btn btn-sm btn-primary">
            Submit form
          </button>
        </div>
      </form>
    </>
  );
};
