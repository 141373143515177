import { useState, useEffect, FormEvent } from 'react';

/* components */
import { Checkbox } from '../../Common/Checkbox/Checkbox';
import { Input } from '../../Common/Input/Input';
import { Select } from '../../Common/Select/Select';

/* configs */
import api from '../../../configs/api';

/* types */
import { License } from '../../../types/License';
import { LicenseRequirement } from '../../../types/LicenseRequirement';

interface Props {
  license: License;
  licenseRequirement?: LicenseRequirement;
  handleSubmit: (data: LicenseRequirement) => void;
}

export const LicenseRequirementForm = (props: Props) => {
  const { license, licenseRequirement } = props;

  const [licenseRequired, setLicenseRequired] = useState<string>(
    props?.licenseRequirement?.license_id || ''
  );
  const [licenses, setLicenses] = useState<License[]>([]);
  const [theory, setTheory] = useState<boolean>(false);

  /* create license requirement */
  const createLicenseRequirement = (licenseRequirement: LicenseRequirement) => {
    api
      .post(`/licenses/${license.id}/requirements`, licenseRequirement)
      .then(() => {
        props.handleSubmit(licenseRequirement);
      });
  };

  /* handle form submit */
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const data = Object.fromEntries(
      new FormData(form).entries()
    ) as unknown as LicenseRequirement;

    data.license_required_id = licenseRequired;
    data.theory_required = theory;

    createLicenseRequirement(data);
  };

  /* get licenses to populate the requirements select */
  useEffect(() => {
    api.get(`/licenses`).then((response) => setLicenses(response.data));
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
      method="post"
      className="flex flex-col gap-4 mt-8"
    >
      {/* age */}
      <Input
        name="age"
        type="number"
        label="Age"
        defaultValue={licenseRequirement?.age}
      />
      {/* license */}
      <Select
        name="license"
        label="License"
        options={licenses.map((license) => {
          return {
            label: license.name,
            value: license.id
          };
        })}
        onChange={(e) => setLicenseRequired(e.target.value)}
      />
      {/* years license held */}
      <Input
        name="years_license_held"
        type="number"
        label="Years License Held"
        defaultValue={licenseRequirement?.years_license_held}
      />
      {/* theory */}
      <div className="flex gap-4">
        <Checkbox
          name="theory"
          onChange={() => setTheory(!theory)}
          checked={theory}
        />
        <label htmlFor="theory" className="text-sm">
          Theory Required
        </label>
      </div>
      {/* actions */}
      <div className="flex justify-end">
        <button type="submit" className="btn btn-sm btn-primary">
          Submit form
        </button>
      </div>
    </form>
  );
};
