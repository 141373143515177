import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

/* components */
import { License } from './License/License';
import { LicenseForm } from '../../../Forms/LicenseForm/LicenseForm';
import { Drawer } from '../../../Common/Drawer/Drawer';
import { Heading } from '../../../Common/Heading/Heading';

/* configs */
import api from '../../../../configs/api';

/* redux */
import { startLoading, stopLoading } from '../../../../redux/loading';

/* types */
import { License as LicenseType } from '../../../../types/License';

export const Licenses = () => {
  const dispatch = useDispatch();

  const [licenses, setLicenses] = useState<LicenseType[]>([]);

  const getLicenses = () => {
    api
      .get(`/licenses`)
      .then((response) => setLicenses(response.data))
      .then(() => dispatch(stopLoading()));
  };

  const createLicense = (license: LicenseType) => {
    api.post(`/licenses`, license).then(() => {
      toast.success('License created!');
      getLicenses();
    });
  };

  useEffect(() => {
    dispatch(startLoading());
    getLicenses();
  }, []); // eslint-disable-line

  return (
    <>
      <Heading title="Licenses">
        {/* new license form */}
        <Drawer
          id="new-license"
          title="New License"
          trigger={
            <label
              htmlFor="new-license"
              className="drawer-button btn btn-primary btn-sm"
            >
              New
            </label>
          }
        >
          <LicenseForm handleSubmit={(license) => createLicense(license)} />
        </Drawer>
      </Heading>
      {/* licenses */}
      <div
        className="grid grid-cols-1 xs:grid-cols-2 
                  lg:grid-cols-3 xl:grid-cols-4 gap-4"
      >
        {licenses.map((license) => (
          <License license={license} onChange={getLicenses} key={license.id} />
        ))}
      </div>
    </>
  );
};
