import { useIsAuthenticated } from '@azure/msal-react';
import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

/* components */
import { Navbar } from './Navbar/Navbar';
import { Menu } from './Menu/Menu';
import { Loading } from '../Common/Loading/Loading';

/* hooks */
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

/* css */
import 'react-toastify/dist/ReactToastify.css';

export const Layout = () => {
  const { width } = useWindowDimensions();
  const isAuthenticated = useIsAuthenticated();

  const loading = useSelector(
    (state: Record<string, Record<string, boolean>>) => state.loading.value
  );

  const [isMenuOpen, setisMenuOpen] = useState<boolean>(
    isAuthenticated && width > 768
  );

  const toggleMenu = () => {
    setisMenuOpen(!isMenuOpen);
  };

  return (
    <div className="h-screen overflow-hidden flex flex-col bg-neutral-100">
      <Navbar toggleMenu={toggleMenu} />
      <div className="flex pt-16 flex-grow">
        {/* menu */}
        <Menu open={isMenuOpen} toggle={toggleMenu} />
        {/* loading overlay */}
        {isAuthenticated && (
          <label
            htmlFor="content"
            className={classNames(
              `fixed w-full h-[calc(100%-4rem)] md:w-[calc(100%-9rem)] 
              flex items-center justify-center bg-neutral-100 right-0 
              transition-all duration-300 ease-in-out`,
              loading ? 'z-30 opacity-100' : 'z-0 opacity-0'
            )}
          >
            <Loading />
          </label>
        )}
        {/* content overlay */}
        {isAuthenticated && (
          <label
            htmlFor="content"
            className={classNames(
              'h-full w-full transition-all duration-300 ease-in-out',
              isMenuOpen
                ? 'bg-black bg-opacity-40 z-30 md:bg-transparent md:-z-10'
                : '-z-10'
            )}
            onClick={toggleMenu}
          />
        )}
        {/* content */}
        <div
          id="content"
          className={classNames(
            isAuthenticated &&
              `overflow-x-hidden fixed h-[calc(100%-4rem)] transition-all 
              duration-300 ease-in-out right-0 px-4 py-2 md:px-6 md:py-4 
              bg-neutral-100`,
            isMenuOpen ? 'w-full md:w-[calc(100%-12rem)]' : 'w-full',
            loading && 'hidden'
          )}
        >
          <Outlet />
        </div>
      </div>
      {/* toast */}
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        toastClassName="bg-neutral-000 text-sm"
      />
    </div>
  );
};
