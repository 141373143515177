import { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

/* components */
import { Input } from '../../../../Common/Input/Input';
import { Checkbox } from '../../../../Common/Checkbox/Checkbox';

/* configs */
import api from '../../../../../configs/api';

/* types */
import { Customer } from '../../../../../types/Customer';
import { Heading } from '../../../../Common/Heading/Heading';

interface Props {
  handleSubmit: (data: Customer) => void;
}

export const CustomerDetails = (props: Props) => {
  const [tcAccepted, setTcAccepted] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const data = Object.fromEntries(
      new FormData(form).entries()
    ) as unknown as Customer;

    if (!tcAccepted) {
      return toast.error('Please accept the terms and conditions.');
    }

    /* format the date of birth */
    data.date_of_birth = moment(data.date_of_birth, 'DD/MM/YYYY').format(
      'YYYY-MM-DD'
    );

    /* check if the customer already exists */
    const customerId = await api
      .get(`/customer_id/${data.email}`)
      .then((response) => response.data)
      .catch(() => false);

    // Do we need to get the customer to confirm their email?
    // Is there a risk of updating someone elses details?

    if (customerId) {
      /* update the customer record */
      api
        .put(`/customers/${customerId}`, data)
        .then((response) => props.handleSubmit(response.data))
        .catch((error) => toast.error(error.message));
    } else {
      /* create the customer record */
      api
        .post('/customers', data)
        .then((response) => props.handleSubmit(response.data))
        .catch((error) => toast.error(error.message));
    }
  };

  return (
    <>
      <Heading title="Customer Details" />
      <form
        onSubmit={handleSubmit}
        method="post"
        className="flex flex-col gap-4"
      >
        {/* first name */}
        <Input name="first_name" label="First Name" type="text" />
        {/* last name */}
        <Input name="last_name" label="Last Name" type="text" />
        {/* email */}
        <Input name="email" label="Email" type="email" />
        {/* phone */}
        <Input name="phone" label="Phone" type="tel" />
        {/* date of birth */}
        <Input
          name="date_of_birth"
          label="Date of Birth"
          type="text"
          placeholder="dd/mm/yyyy"
        />
        <br />
        {/* terms and conditions */}
        <div className="flex gap-2">
          <Checkbox
            name="terms_and_conditions"
            checked={tcAccepted}
            onChange={() => setTcAccepted(!tcAccepted)}
          />
          <label htmlFor="terms_and_conditions">
            I agree to the terms and conditions
          </label>
        </div>
        {/* actions */}
        <div className="flex justify-end">
          <button
            type="submit"
            className="btn btn-sm btn-primary"
            disabled={!tcAccepted}
          >
            Next
          </button>
        </div>
      </form>
    </>
  );
};
