import { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

/* redux */
import { startLoading, stopLoading } from '../../../../../redux/loading';

/* config */
import api from '../../../../../configs/api';

/* types */
import { Course } from '../../../../../types/Course';
import { Customer } from '../../../../../types/Customer';
import { Heading } from '../../../../Common/Heading/Heading';

interface Props {
  customer: Customer;
  handleSubmit: (data: Course) => void;
}

export const CourseSelection = (props: Props) => {
  const dispatch = useDispatch();

  const [courses, setCourses] = useState<Course[]>([]);
  const [course, setCourse] = useState<Course>();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    /* validate a course was selected */
    if (!course) return console.log('Course is required.');

    props.handleSubmit(course);
  };

  /* get all the courses and filter them based on their requirements */
  useEffect(() => {
    dispatch(startLoading());

    /* courses */
    api.get(`/courses`).then((response) => {
      setCourses(response.data.filter((c: Course) => !c.deleted));
      dispatch(stopLoading());
    });
  }, []); // eslint-disable-line

  return (
    <>
      <Heading title="Course Selection" />
      <div>Which course are you interested in?</div>
      <br />
      <form
        onSubmit={handleSubmit}
        method="post"
        className="flex flex-col gap-4"
      >
        {/* courses */}
        <div className="flex gap-4">
          {courses.map((c) => (
            <div
              key={c.id}
              onClick={() => setCourse(c)}
              className={classNames(
                'border border-neutral-200 rounded p-4 cursor-pointer',
                'flex flex-col gap-2',
                c.id === course?.id && 'border-primary'
              )}
            >
              <div className="font-bold">{c.name}</div>
              <div>{c.description}</div>
              <div>£{c.price}</div>
              <div>{c.days} days</div>
            </div>
          ))}
        </div>
        {/* actions */}
        <div className="flex justify-end">
          <button
            type="submit"
            className="btn btn-sm btn-primary"
            disabled={!course}
          >
            Next
          </button>
        </div>
      </form>
    </>
  );
};
