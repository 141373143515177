import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

/* components */
import { Course } from './Course/Course';
import { CourseForm } from '../../../Forms/CourseForm/CourseForm';
import { Drawer } from '../../../Common/Drawer/Drawer';
import { Heading } from '../../../Common/Heading/Heading';

/* configs */
import api from '../../../../configs/api';

/* redux */
import { startLoading, stopLoading } from '../../../../redux/loading';

/* types */
import { Course as CourseType } from '../../../../types/Course';

export const Courses = () => {
  const dispatch = useDispatch();

  const [courses, setCourses] = useState<CourseType[]>([]);

  const getCourses = () => {
    api
      .get(`/courses`)
      .then((response) => setCourses(response.data))
      .then(() => dispatch(stopLoading()));
  };

  const createCourse = (course: CourseType) => {
    api
      .post(`/courses`, course)
      .then(() => {
        document.getElementById('new-course')?.click();
        toast.success('Course created!');
        getCourses();
      })
      .catch((error) => toast.error(error.response.data.message));
  };

  useEffect(() => {
    dispatch(startLoading());
    getCourses();
  }, []); // eslint-disable-line

  return (
    <>
      <Heading title="Courses">
        {/* new course form */}
        <Drawer
          id="new-course"
          title="New Course"
          trigger={
            <label
              htmlFor="new-course"
              className="drawer-button btn btn-primary btn-sm"
            >
              New
            </label>
          }
        >
          <CourseForm handleSubmit={(course) => createCourse(course)} />
        </Drawer>
      </Heading>
      {/* courses */}
      <div
        className="grid grid-cols-1 xs:grid-cols-2 
                  lg:grid-cols-3 xl:grid-cols-4 gap-4"
      >
        {courses.map((course) => (
          <Course course={course} onChange={getCourses} key={course.id} />
        ))}
      </div>
    </>
  );
};
