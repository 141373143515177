import { FormEvent } from 'react';

/* components */
import { Input } from '../../Common/Input/Input';

/* types */
import { Address } from '../../../types/Address';

/* props */
interface Props {
  data?: Address;
  handleSubmit: (data: Address) => void;
}

export const AddressForm = (props: Props) => {
  const address = props.data;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const data = Object.fromEntries(
      new FormData(form).entries()
    ) as unknown as Address;

    props.handleSubmit({ ...address, ...data });
  };

  return (
    <form
      onSubmit={handleSubmit}
      method="post"
      className="flex flex-col gap-4 mt-8"
    >
      {/* address line 1 */}
      <Input
        name="address_line_1"
        type="text"
        label="Address Line 1"
        defaultValue={address?.address_line_1}
      />
      {/* address line 2 */}
      <Input
        name="address_line_2"
        type="text"
        label="Address Line 2"
        defaultValue={address?.address_line_2}
      />
      {/* city */}
      <Input
        name="city"
        type="text"
        label="City"
        defaultValue={address?.city}
      />
      {/* county */}
      <Input
        name="county"
        type="text"
        label="County"
        defaultValue={address?.county}
      />
      {/* postcode */}
      <Input
        name="postcode"
        type="text"
        label="Postcode"
        defaultValue={address?.postcode}
      />
      {/* submit */}
      <div className="flex justify-end mt-4">
        <button type="submit" className="btn btn-sm btn-primary">
          Submit form
        </button>
      </div>
    </form>
  );
};
