import moment from 'moment';

/* icons */
import { faDriversLicense, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* types */
import { Booking } from '../../../../../types/Booking';
import { CourseDate } from '../../../../../types/Course';

interface Props {
  date: Date;
  courses: CourseDate[];
  bookings: Booking[];
}

export const CalendarTile = (props: Props) => {
  const { date, courses, bookings } = props;

  const dateString = moment(date).format('YYYY-MM-DD');

  const coursesForDate = courses.filter(
    (c) => moment(c.date).format('YYYY-MM-DD') === dateString
  );

  const bookingsForDate = bookings.filter(
    (b) => moment(b.date).format('YYYY-MM-DD') === dateString
  );

  return (
    <div className="mt-2 p-1 text-xs font-light h-12">
      {coursesForDate.length > 0 && (
        <div className="flex flex-col gap-2 items-center">
          {/* courses */}
          <div className="flex items-center gap-1">
            <FontAwesomeIcon icon={faDriversLicense} />
            <div className="hidden xs:block">
              x {coursesForDate.length || 0}
            </div>
          </div>
          {/* bookings */}
          <div className="flex items-center gap-1">
            <FontAwesomeIcon icon={faUser} />
            <div className="hidden xs:block">
              {bookingsForDate.length}/
              {coursesForDate
                .map((course) => course.capacity)
                .reduce((a, b) => a + b)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
