import { ChangeEvent } from 'react';

interface Option {
  label: string;
  value: string;
}

interface Props {
  name: string;
  label?: string;
  value?: string | number;
  hideDefault?: boolean;
  defaultValue?: string | number;
  options: Option[];
  onChange: (event: ChangeEvent<HTMLSelectElement>) => unknown;
}

export const Select = (props: Props) => {
  const { label, name, value, hideDefault, options } = props;

  return (
    <label className="form-control w-full max-w-xs" htmlFor={name}>
      {/* label */}
      {label && (
        <div className="label">
          <span className="label-text">{label}</span>
        </div>
      )}
      {/* select */}
      <select
        name={name}
        onChange={(e) => props.onChange(e)}
        className="select select-bordered select-sm w-full max-w-xs"
      >
        {/* default */}
        {!hideDefault && (
          <option selected value={undefined}>
            None
          </option>
        )}
        {/* options */}
        {options.map((option, index) => (
          <option
            key={index}
            selected={option.value === value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
};
