import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createColumnHelper } from '@tanstack/react-table';

/* components */
import { AddressForm } from '../../../Forms/AddressForm/AddressForm';
import { Drawer } from '../../../Common/Drawer/Drawer';
import { Heading } from '../../../Common/Heading/Heading';
import { Table } from '../../../Common/Table/Table';
import { TableCheckbox } from '../../../Common/Table/TableCheckbox/TableCheckbox';

/* configs */
import api from '../../../../configs/api';

/* redux */
import { startLoading, stopLoading } from '../../../../redux/loading';

/* types */
import { Address as AddressType } from '../../../../types/Address';

export const Address = () => {
  const dispatch = useDispatch();

  const [addresses, setAddresses] = useState<AddressType[]>([]);

  /* get addresses */
  const getAddresses = () => {
    dispatch(startLoading());

    api
      .get(`/addresses`)
      .then((response) => setAddresses(response.data))
      .then(() => dispatch(stopLoading()));
  };

  /* create address */
  const createAddress = (address: AddressType) => {
    api.post(`/addresses`, address).then(() => {
      toast.success('Address created!');
      getAddresses();
    });
  };

  /* update address */
  const updateAddress = (address: AddressType) => {
    api.put(`/addresses/${address.id}`, address).then(() => {
      toast.success('Address updated!');
      getAddresses();
    });
  };

  useEffect(() => {
    getAddresses();
  }, []); // eslint-disable-line

  const columnHelper = createColumnHelper<AddressType>();

  const columns = [
    columnHelper.display({
      id: 'select',
      header: ({ table }) => (
        <TableCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler()
          }}
        />
      ),
      cell: ({ row }) => (
        <TableCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler()
          }}
        />
      )
    }),
    columnHelper.accessor('address_line_1', {
      header: () => <span>Address Line 1</span>,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('address_line_2', {
      header: () => <span>Address Line 2</span>,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('city', {
      header: () => <span>City</span>,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('county', {
      header: () => <span>County</span>,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('postcode', {
      header: () => <span>Postcode</span>,
      cell: (info) => info.getValue()
    })
  ];

  return (
    <>
      <Heading title="Addresses">
        {/* new address form */}
        <Drawer
          id="new-address"
          title="New Address"
          trigger={
            <label
              htmlFor="new-address"
              className="drawer-button btn btn-primary btn-sm"
            >
              New
            </label>
          }
        >
          <AddressForm handleSubmit={(address) => createAddress(address)} />
        </Drawer>
      </Heading>
      {/* addresses */}
      <Table
        columns={columns}
        data={addresses.filter((address) => !address.deleted)}
        drawer={{
          id: 'edit-address',
          name: 'Edit Address',
          content: (
            <AddressForm handleSubmit={(address) => updateAddress(address)} />
          )
        }}
        enableRowSelection
        fixedPagination
      />
    </>
  );
};
