import { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  children: ReactNode;
}

export const CardOptions = (props: Props) => {
  const { className, children } = props;

  return (
    <div className={classNames('card-options', className)}>{children}</div>
  );
};
